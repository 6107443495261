/* eslint-disable no-console */
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-console
        console.info('requesting..', queryParams)
        // eslint-disable-next-line no-console
        console.log('hi-admin')
        axios
          .get('admin/users/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/user/view/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/user/edit-user/${id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editIntegration(ctx, { id, postData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/user/edit-integration/${id}`, postData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPrinters(ctx, { id, postData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/user/edit-printers/${id}`, postData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/user/add', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
