<template>
  <div>
    <b-tabs fill>
      <b-tab title="Dashboard" active>
        <div
          class="m-1 mb-3 d-flex justify-content-end"
          style="position: -webkit-sticky; position: sticky; top: 100px; z-index: 9"
        >
          <div class="d-flex align-items-center" style="">
            <div v-show="day === 'custom'">
              <flat-pickr
                ref="datepickr"
                v-model="dateRange"
                class="form-control"
                :config="{ mode: 'range' }"
                @on-close="ordersAnalytics(null, $event, true)"
              />
            </div>
            <b-dropdown
              id="days-selector"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loadingAnalytics"
              :text="dayString"
              variant="primary"
              class="ml-2"
            >
              <b-dropdown-item
                v-for="x of [0, 1, 7, 30, 'custom']"
                :key="x"
                :value="x"
                @click="
                  ordersAnalytics(x)
                  day = x
                "
              >
                {{
                  x === 0
                    ? 'today'
                    : x === 1
                    ? 'yesterday'
                    : x === 'custom'
                    ? 'Custom Range'
                    : 'last ' + x + ' days'
                }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <b-card v-if="loadingAnalytics" class="card-zero-padding">
          <div class="d-flex justify-content-center flex-column align-items-center m-3">
            <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            <div class="m-2">Loading Analytics, Please wait...</div>
          </div>
        </b-card>
        <div v-else class="after-loading">
          <b-row class="match-height">
            <b-col>
              <b-tabs fill>
                <b-tab title="All" active>
                  <ecommerce-statistics :data="statisticsItems" />
                  <servers-statistics :data="serversStatisticsItems" />
                </b-tab>
                <b-tab title="Morning">
                  <ecommerce-statistics :data="statisticsItemsMorning" />
                  <servers-statistics :data="serversStatisticsItemsMorning" />
                </b-tab>
                <b-tab title="Evening">
                  <ecommerce-statistics :data="statisticsItemsEvening" />
                  <servers-statistics :data="serversStatisticsItemsEvening" />
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
          <div class="tables">
            <b-card>
              <b-tabs fill>
                <!--                <b-tab title="Customers" active>-->
                <!--                  <b-table-->
                <!--                    hover-->
                <!--                    responsive-->
                <!--                    :borderless="true"-->
                <!--                    :items="customers"-->
                <!--                    :fields="customersFields"-->
                <!--                  >-->
                <!--                    <template #cell(full_name)="data">-->
                <!--                      <h5 class="text-capitalize">{{ data.value }}</h5>-->
                <!--                    </template>-->
                <!--                    <template #cell(phone)="data">-->
                <!--                      <h5>{{ data.value }}</h5>-->
                <!--                    </template>-->
                <!--                    <template #cell(count)="data">-->
                <!--                      <h5>{{ data.value }}</h5>-->
                <!--                    </template>-->
                <!--                    <template #cell(total)="data">-->
                <!--                      <h5>{{ `$${data.value}` }}</h5>-->
                <!--                    </template>-->
                <!--                  </b-table>-->
                <!--                </b-tab>-->
                <b-tab title="Orders">
                  <OrdersPOS :orders-list="ordersList"></OrdersPOS>
                </b-tab>
                <b-tab title="Items">
                  <b-table
                    hover
                    responsive
                    :borderless="true"
                    :items="orders.items"
                    :fields="itemsFields"
                  >
                    <template #cell(name)="data">
                      <h5 class="text-capitalize">{{ data.value }}</h5>
                    </template>
                    <template #cell(count)="data">
                      <h5>{{ data.value }}</h5>
                    </template>
                    <template #cell(total)="data">
                      <h5>{{ `$${data.value / 100}` }}</h5>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Tables">
                  <b-table
                    hover
                    responsive
                    :borderless="true"
                    :items="tables"
                    :fields="tablesFields"
                  >
                    <template #cell(table)="data">
                      <h5>{{ data.value }}</h5>
                    </template>
                    <template #cell(count)="data">
                      <h5>{{ data.value }}</h5>
                    </template>
                    <template #cell(total)="data">
                      <h5>{{ `$${data.value / 100}` }}</h5>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Item Types">
                  <div
                    v-if="
                      orders &&
                      orders.providers &&
                      orders.providers.totalSeries &&
                      orders.providers.totalSeries.length >= 1
                    "
                    class="d-flex flex-wrap justify-content-around"
                  >
                    <vue-apex-charts
                      type="donut"
                      height="500px"
                      width="100%"
                      class="my-2"
                      :options="donutTotalChartOptions"
                      :series="orders.providers.totalSeries"
                    />
                    <vue-apex-charts
                      type="donut"
                      height="500px"
                      width="100%"
                      class="my-2"
                      :options="donutCountChartOptions"
                      :series="orders.providers.countSeries"
                    />
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </b-tab>
      <b-tab title="Per Hour">
        <b-card v-if="loadingLive" class="card-zero-padding">
          <div class="d-flex justify-content-center flex-column align-items-center m-3">
            <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            <div class="m-2">Loading Analytics, Please wait...</div>
          </div>
        </b-card>
        <b-card v-else class="card-zero-padding">
          <b-card-body class="p-0">
            <statistic-card-with-area-chart
              v-if="
                perHourStatistics &&
                perHourStatistics.series[0].data &&
                perHourStatistics.series[0].data.length > 0
              "
              icon="UsersIcon"
              :statistic="kFormatter(perHourStatistics.count)"
              :statistic2="'$' + kFormatter(perHourStatistics.total)"
              :statistic3="
                '$' + (perHourStatistics.total / perHourStatistics.count).toFixed(2)
              "
              statistic-title="COMPLETED ORDERS"
              statistic-title2="TOTAL SALES"
              statistic-title3="AOV"
              :chart-data="perHourStatistics.series"
              :chart-options="chartOptionsPerHour"
            />
          </b-card-body>
        </b-card>
      </b-tab>
      <b-tab title="Per Week">
        <b-card v-if="loadingLive" class="card-zero-padding">
          <div class="d-flex justify-content-center flex-column align-items-center m-3">
            <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            <div class="m-2">Loading Analytics, Please wait...</div>
          </div>
        </b-card>
        <b-card v-else class="card-zero-padding">
          <b-card-body class="p-0">
            <statistic-card-with-area-chart
              v-if="
                perWeekStatistics &&
                perWeekStatistics.series[0] &&
                perWeekStatistics.series[0].data.length > 0
              "
              icon="UsersIcon"
              :statistic="kFormatter(perWeekStatistics.count)"
              :statistic2="'$' + kFormatter(perWeekStatistics.total)"
              :statistic3="
                '$' +
                (perWeekStatistics.count === 0
                  ? 0
                  : (perWeekStatistics.total / perWeekStatistics.count).toFixed(2))
              "
              statistic-title="COMPLETED ORDERS"
              statistic-title2="TOTAL SALES"
              statistic-title3="AOV"
              :chart-data="perWeekStatistics.series"
              :chart-options="chartOptions"
            />
          </b-card-body>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<style scoped></style>
<style>
@media all and (min-width: 550px) {
  .card-zero-padding .card-body {
    padding: 20px;
  }
}
.card-zero-padding .card-body {
  padding: 3px;
}
.dropdown-menu {
  max-height: 420px;
  overflow-y: auto;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<script>
/* eslint-disable no-console */
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BTable,
  BTabs,
  BTab,
  // BFormGroup,
  VBModal,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from '@/layouts/components/StatisticCardWithAreaChart.vue'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import OrdersPOS from '@/views/client/orders/OrdersPOS.vue'
import flatPickr from 'vue-flatpickr-component'
import EcommerceStatistics from './Statistics.vue'
import ServersStatistics from './ServersStatistics.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BTable,
    BTabs,
    BTab,
    // BFormGroup,
    VueApexCharts,
    EcommerceStatistics,
    ServersStatistics,
    StatisticCardWithAreaChart,
    OrdersPOS,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      donutCountChartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)} Items`
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: 'Items Count',
        },
      },
      donutTotalChartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `$${parseInt(val)}`
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: 'Items Revenue (Subtotal)',
        },
      },
      orders: {},
      chartOptions: {
        grid: {
          show: true,
          padding: {
            left: 20,
            right: 20,
            top: 40,
            bottom: 60,
          },
        },
        colors: ['#ffe700', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          id: 'orders-analytics',
          toolbar: {
            show: true,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'string',
          lines: {
            show: true,
          },
          style: {
            colors: $themeColors.primary,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            show: true,
            style: {
              colors: $themeColors.primary,
            },
          },
        },
        yaxis: [
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
          },
          x: {
            show: true,
          },
          y: [
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `${y.toFixed(0)} orders`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
          ],
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: -10,
        },
        theme: {
          monochrome: {
            enabled: false,
            color: $themeColors.primary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      chartOptionsPerHour: {
        grid: {
          show: true,
          padding: {
            left: 20,
            right: 20,
            top: 40,
            bottom: 60,
          },
        },
        colors: ['#ffe700', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          id: 'orders-analytics',
          toolbar: {
            show: true,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'string',
          lines: {
            show: true,
          },
          style: {
            colors: $themeColors.primary,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            show: true,
            style: {
              colors: $themeColors.primary,
            },
          },
        },
        yaxis: [
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
          },
          x: {
            show: true,
          },
          y: [
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `${y.toFixed(0)} orders`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
          ],
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: -10,
        },
        theme: {
          monochrome: {
            enabled: false,
            color: $themeColors.primary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      loadingAnalytics: false,
      dateRange: '',
      day: 0,
      itemsFields: [
        {
          key: 'name',
          label: 'Item Name',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Orders',
          sortable: true,
        },
        { key: 'total', label: 'Sales', sortable: true },
      ],
      tables: [],
      tablesFields: [
        {
          key: 'table',
          label: 'Table',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Orders',
          sortable: true,
        },
        { key: 'total', label: 'Sales', sortable: true },
      ],
      customers: [],
      customersFields: [
        {
          key: 'full_name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Mobile',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Orders',
          sortable: true,
        },
        { key: 'total', label: 'Sales', sortable: true },
      ],
      serversStatisticsItems: {},
      serversStatisticsItemsMorning: {},
      serversStatisticsItemsEvening: {},
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Voided Orders',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Items',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],
      statisticsItemsMorning: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Voided Orders',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: 'XX',
          subtitle: 'Guests',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],
      statisticsItemsEvening: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Voided Orders',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: 'XX',
          subtitle: 'Guests',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],
      perWeekStatistics: {
        series: { name: '', data: [] },
      },
      perHourStatistics: {
        series: { name: '', data: [] },
      },
      loadingLive: true,
      ordersList: [],
    }
  },
  computed: {
    // eslint-disable-next-line object-shorthand,func-names
    dayString: function () {
      if (this.day === 0) {
        return 'today'
      }
      if (this.day === 1) {
        return 'yesterday'
      }
      if (this.day === 'custom') {
        return 'Custom Range'
      }
      return `last ${this.day} days`
    },
  },
  async created() {
    /*    const This = this
    setInterval(() => {
      console.log(This.chartOptions.xaxis.categories)
    }, 2000) */

    await this.ordersAnalytics(this.day)
    await this.liveAnalytics()
  },
  methods: {
    kFormatter,
    async ordersAnalytics(days, dateRange, submit) {
      console.log(days, dateRange, submit)
      if (!submit && days === 'custom') {
        setTimeout(() => this.$refs.datepickr.fp.open(), 200)

        // this.showDatePicker = true
        return
      }
      if (!submit) this.showDatePicker = false
      if (dateRange && dateRange.length === 2)
        // eslint-disable-next-line no-param-reassign
        dateRange = `${dateRange[0].getTime()}-${dateRange[1].getTime()}`
      else if (dateRange && dateRange.length === 1) {
        // eslint-disable-next-line no-param-reassign
        dateRange = `${dateRange[0].getTime()}-${dateRange[0].getTime()}`
        console.log(dateRange)
      }
      try {
        this.loadingAnalytics = true
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`pos/analytics/days/${days}/${dateRange || ''}`)
        response = response.data
        if (response.type === 'success') {
          const { orders, ordersList, customers, tables } = response
          // this.chartOptions.labels = response.orders.labels
          this.orders.count = orders.count
          this.orders.total = orders.total
          this.orders.voidedCount = orders.voidedCount
          this.orders.voidedTotal = orders.voidedTotal
          this.orders.series = orders.series
          this.ordersList = ordersList

          console.log(this.ordersList)

          // Items
          this.orders.items = Object.values(orders.rawItems.series).sort(this.compare)

          // Customers
          this.customers = Object.values(customers.series).sort(this.compare)

          // Tables
          this.tables = Object.values(tables.series).sort(this.compare)

          // Servers Statistics
          this.serversStatisticsItems = orders.servers
          this.serversStatisticsItemsMorning = orders.morning.servers
          this.serversStatisticsItemsEvening = orders.evening.servers

          // Statistics
          this.statisticsItems[2].title = kFormatter(this.orders.items.length)
          this.statisticsItems[0].title = kFormatter(this.orders.count)
          this.statisticsItems[3].title = `$${this.orders.total / 100}`
          this.statisticsItems[1].title = `${kFormatter(
            this.orders.voidedCount
          )} ($${kFormatter(this.orders.voidedTotal / 100)})`

          // Statistics
          // this.statisticsItemsMorning[2].title = kFormatter(this.orders.items.length)
          this.statisticsItemsMorning[0].title = kFormatter(
            response.orders.morning.allServers.count
          )
          this.statisticsItemsMorning[3].title = `$${
            response.orders.morning.allServers.total / 100
          }`
          this.statisticsItemsMorning[1].title = `${kFormatter(
            response.orders.morning.allServers.voidedCount
          )} ($${kFormatter(response.orders.morning.allServers.voidedTotal / 100)})`

          // Statistics
          // this.statisticsItemsEvening[2].title = kFormatter(response.orders.items.length)
          this.statisticsItemsEvening[0].title = kFormatter(
            response.orders.evening.allServers.count
          )
          this.statisticsItemsEvening[3].title = `$${
            response.orders.evening.allServers.total / 100
          }`
          this.statisticsItemsEvening[1].title = `${kFormatter(
            response.orders.evening.allServers.voidedCount
          )} ($${kFormatter(response.orders.evening.allServers.voidedTotal / 100)})`

          // Item Types - Food/Drink
          this.orders.providers = {}
          this.donutTotalChartOptions.labels = Object.keys(response.orders.itemType)
          this.donutCountChartOptions.labels = Object.keys(response.orders.itemType)
          this.orders.providers.totalSeries = Object.values(response.orders.itemType).map(
            itemType => itemType.total / 100
          )
          this.orders.providers.countSeries = Object.values(response.orders.itemType).map(
            itemType => itemType.count
          )
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.loadingAnalytics = false
      }
    },

    async liveAnalytics() {
      try {
        this.loadingLive = true
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`pos/analytics/live`)
        response = response.data
        if (response.type === 'success') {
          const perWeek = response.perWeekStatistics

          // Series data
          this.perWeekStatistics.series = [
            {
              name: 'Orders count',
              data: [],
            },
            {
              name: 'Total Orders Value',
              data: [],
            } /*
            {
              name: 'Min. order value',
              data: [],
            },
            {
              name: 'Max. order value',
              data: [],
            }, */,
            {
              name: 'Avg. order value',
              data: [],
            },
          ]
          this.chartOptions.labels = []
          Object.values(perWeek.series).forEach(day => {
            // Series labels
            this.chartOptions.labels.push(day.date)

            this.perWeekStatistics.series[0].data.push(day.count)
            this.perWeekStatistics.series[1].data.push(day.total / 100)
            // this.perWeekStatistics.series[2].data.push(day.min)
            // this.perWeekStatistics.series[3].data.push(day.max)
            this.perWeekStatistics.series[2].data.push(
              day.count === 0 ? 0 : (day.total / 100 / day.count).toFixed(2)
            )
          })

          // Total revenue of the week
          this.perWeekStatistics.total = perWeek.total / 100
          // Total orders or the week
          this.perWeekStatistics.count = perWeek.count

          // Per Hour Analytics
          const perHour = response.perHourStatistics

          // Series data
          this.perHourStatistics.series = [
            {
              name: 'Orders count',
              data: [],
            },
            {
              name: 'Total Orders Value',
              data: [],
            },
            {
              name: 'Avg. order value',
              data: [],
            },
          ]
          this.chartOptionsPerHour.labels = []
          Object.values(perHour.series).forEach(day => {
            // Series labels
            this.chartOptions.labels.push(day.hour)

            this.perHourStatistics.series[0].data.push(day.count)
            this.perHourStatistics.series[1].data.push(day.total / 100)
            // this.perHourStatistics.series[2].data.push(day.min)
            // this.perHourStatistics.series[3].data.push(day.max)
            this.perHourStatistics.series[2].data.push(
              day.count === 0 ? 0 : (day.total / 100 / day.count).toFixed(2)
            )
          })

          // Total revenue of the hour
          this.perHourStatistics.total = perHour.total / 100
          // Total orders or the hour
          this.perHourStatistics.count = perHour.count
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.loadingLive = false
      }
    },
    compare(a, b) {
      if (a.total > b.total) {
        return -1
      }
      if (a.total < b.total) {
        return 1
      }
      return 0
    },
  },
}
</script>
