<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2 d-none">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="uData.avatar"
          :text="avatarText(uData.name)"
          :variant="`light-${resolveUserRoleVariant(uData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ uData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="full-name">
            <b-form-input id="full-name" v-model="uData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <!--        <b-col cols="12" md="4">-->
        <!--          <b-form-group label="Mobile" label-for="mobile">-->
        <!--            <b-form-input id="mobile" v-model="uData.mobile" />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="uData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="user-status">
            <v-select
              v-model="uData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Current Plan -->
        <!--        <b-col cols="12" md="4">-->
        <!--          <b-form-group label="Current Plan" label-for="user-status">-->
        <!--            <v-select-->
        <!--              v-model="uData.currentPlan"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              :options="currentPlanOptions"-->
        <!--              :reduce="val => val.value"-->
        <!--              :clearable="false"-->
        <!--              input-id="user-currentPlan"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Plan Validity -->
        <!--        <b-col cols="12" md="4">-->
        <!--          <b-form-group label="Plan Validity" label-for="planValidity">-->
        <!--            <b-form-datepicker id="planValidity" v-model="uData.planValidity" />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group label="User Role" label-for="user-role">
            <v-select
              v-model="uData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Address -->
        <b-col cols="12" md="4">
          <b-form-group label="Address" label-for="address">
            <b-form-input id="address" v-model="uData.address" />
          </b-form-group>
        </b-col>

        <!-- Field: Country -->
        <!--        <b-col cols="12" md="4">-->
        <!--          <b-form-group label="Country" label-for="user-country">-->
        <!--            <v-select-->
        <!--              v-model="uData.country"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              :options="countries"-->
        <!--              :clearable="false"-->
        <!--              input-id="user-country"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Password -->
        <b-col cols="12" md="4">
          <b-form-group label="Password" label-for="password">
            <b-form-input id="password" v-model="uData.password" type="password" />
          </b-form-group>
        </b-col>

        <!-- Field: TimeZone -->
        <b-col cols="12" md="4">
          <b-form-group label="TimeZone" label-for="user-timezone">
            <v-select
              v-model="uData.timezone"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tzInts"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-timezone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Company -->
        <!--        <b-col cols="12" md="4">-->
        <!--          <b-form-group label="Company" label-for="company">-->
        <!--            <b-form-input id="company" v-model="uData.company" />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!--    <b-card no-body class="border mt-1 d-none">-->
    <!--      <b-card-header class="p-1">-->
    <!--        <b-card-title class="font-medium-2">-->
    <!--          <feather-icon icon="LockIcon" size="18" />-->
    <!--          <span class="align-middle ml-50">Permission</span>-->
    <!--        </b-card-title>-->
    <!--      </b-card-header>-->
    <!--      <b-table borderless responsive class="mb-0" :items="permissionsData">-->
    <!--        <template #cell(module)="data">-->
    <!--          {{ data.value }}-->
    <!--        </template>-->
    <!--        <template #cell()="data">-->
    <!--          <b-form-checkbox :checked="data.value" />-->
    <!--        </template>-->
    <!--      </b-table>-->
    <!--    </b-card>-->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="editUser"
    >
      Save Changes
    </b-button>
    <b-button
      v-if="false"
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
/* eslint-disable no-console */
import {
  BButton,
  // BFormDatepicker,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  // BTable,
  // BCard,
  // BCardHeader,
  // BCardTitle,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { reactive, ref, watchEffect } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    // BFormDatepicker,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    const router = useRouter()

    const roleOptions = [{ label: 'Staff', value: 'staff' }]

    const currentPlanOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Advanced', value: 'advanced' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const tzInts = [
      {
        label: '(GMT-12:00) International Date Line West',
        value: 'Etc/GMT+12',
      },
      { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
      { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
      { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
      {
        label: '(GMT-08:00) Pacific Time (US & Canada)',
        value: 'America/Los_Angeles',
      },
      {
        label: '(GMT-08:00) Tijuana, Baja California',
        value: 'America/Tijuana',
      },
      { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
      {
        label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
        value: 'America/Chihuahua',
      },
      {
        label: '(GMT-07:00) Mountain Time (US & Canada)',
        value: 'US/Mountain',
      },
      { label: '(GMT-06:00) Central America', value: 'America/Managua' },
      { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
      {
        label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
        value: 'America/Mexico_City',
      },
      { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
      {
        label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
        value: 'America/Bogota',
      },
      { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
      { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
      { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
      { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
      { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
      { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
      { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
      { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
      {
        label: '(GMT-03:00) Buenos Aires, Georgetown',
        value: 'America/Argentina/Buenos_Aires',
      },
      { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
      { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
      { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
      { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
      { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
      {
        label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
        value: 'Africa/Casablanca',
      },
      {
        label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
        value: 'Etc/Greenwich',
      },
      {
        label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        value: 'Europe/Amsterdam',
      },
      {
        label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        value: 'Europe/Belgrade',
      },
      {
        label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
        value: 'Europe/Brussels',
      },
      {
        label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        value: 'Europe/Sarajevo',
      },
      { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
      { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
      {
        label: '(GMT+02:00) Athens, Bucharest, Istanbul',
        value: 'Europe/Athens',
      },
      { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
      { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
      { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
      {
        label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        value: 'Europe/Helsinki',
      },
      { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
      { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
      { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
      { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
      {
        label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
        value: 'Europe/Moscow',
      },
      { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
      { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
      { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
      { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
      { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
      { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
      { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
      { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
      {
        label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
        value: 'Asia/Karachi',
      },
      {
        label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        value: 'Asia/Calcutta',
      },
      { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
      { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
      { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
      { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
      { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
      { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
      { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
      {
        label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        value: 'Asia/Hong_Kong',
      },
      {
        label: '(GMT+08:00) Kuala Lumpur, Singapore',
        value: 'Asia/Kuala_Lumpur',
      },
      { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
      { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
      { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
      { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
      { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
      { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
      { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
      { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
      { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
      {
        label: '(GMT+10:00) Canberra, Melbourne, Sydney',
        value: 'Australia/Canberra',
      },
      { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
      { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
      { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
      {
        label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
        value: 'Asia/Magadan',
      },
      { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
      {
        label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
        value: 'Pacific/Fiji',
      },
      { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
    ]

    // const permissionsData = [
    //   {
    //     module: 'Admin',
    //     read: true,
    //     write: false,
    //     create: false,
    //     delete: false,
    //   },
    //   {
    //     module: 'Staff',
    //     read: false,
    //     write: true,
    //     create: false,
    //     delete: false,
    //   },
    //   {
    //     module: 'Author',
    //     read: true,
    //     write: false,
    //     create: true,
    //     delete: false,
    //   },
    //   {
    //     module: 'Contributor',
    //     read: false,
    //     write: false,
    //     create: false,
    //     delete: false,
    //   },
    //   {
    //     module: 'User',
    //     read: false,
    //     write: false,
    //     create: false,
    //     delete: true,
    //   },
    // ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    let uData = reactive({})

    // watch props.userData for changes and update the value of uData
    watchEffect(() => {
      uData = props.userData
    })

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // TODO fix mutating props
      // eslint-disable-next-line vue/no-mutating-props,no-param-reassign
      uData.avatar = base64
    })

    const editUser = () => {
      store
        .dispatch('staff-user/editUser', {
          id: /* testing */ router.route.value.query.id,
          userData: uData,
        })
        // eslint-disable-next-line no-console
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.error('something went wrong', error)
        })
    }

    return {
      resolveUserRoleVariant,
      avatarText,
      uData,
      roleOptions,
      currentPlanOptions,
      statusOptions,
      tzInts,
      // permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      editUser,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
