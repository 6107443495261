<template>
  <div>
    <b-tabs fill>
      <b-tab title="Dashboard" active>
        <div
          class="m-1 mb-3 d-flex justify-content-end"
          style="position: -webkit-sticky; position: sticky; top: 100px; z-index: 9"
        >
          <div class="" style="">
            <b-dropdown
              id="days-selector"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loadingAnalytics"
              :text="dayString"
              variant="primary"
              class="ml-2"
            >
              <b-dropdown-item
                v-for="(x, count) in 51"
                :key="count"
                :value="count"
                @click="
                  ordersAnalytics(count)
                  day = count
                "
              >
                {{
                  count === 0 ? 'today' : count === 1 ? 'yesterday' : 'last ' + count + ' days'
                }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <b-card v-if="loadingAnalytics" class="card-zero-padding">
          <div class="d-flex justify-content-center flex-column align-items-center m-3">
            <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            <div class="m-2">Loading Analytics, Please wait...</div>
          </div>
        </b-card>
        <div v-else class="after-loading">
          <b-row class="match-height">
            <b-col>
              <ecommerce-statistics :data="statisticsItems" />
            </b-col>
          </b-row>
          <div class="tables">
            <b-card>
              <b-tabs fill>
                <b-tab title="Customers" active>
                  <b-table
                    hover
                    responsive
                    :borderless="true"
                    :items="customers"
                    :fields="customersFields"
                  >
                    <template #cell(full_name)="data">
                      <h5 class="text-capitalize">{{ data.value }}</h5>
                    </template>
                    <template #cell(phone)="data">
                      <h5>{{ data.value }}</h5>
                    </template>
                    <template #cell(count)="data">
                      <h5>{{ data.value }}</h5>
                    </template>
                    <template #cell(total)="data">
                      <h5>{{ `$${data.value}` }}</h5>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Items">
                  <b-table
                    hover
                    responsive
                    :borderless="true"
                    :items="orders.items"
                    :fields="itemsFields"
                  >
                    <template #cell(name)="data">
                      <h5 class="text-capitalize">{{ data.value }}</h5>
                    </template>
                    <template #cell(count)="data">
                      <h5>{{ data.value }}</h5>
                    </template>
                    <template #cell(total)="data">
                      <h5>{{ `$${data.value}` }}</h5>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Tables">
                  <b-table
                    hover
                    responsive
                    :borderless="true"
                    :items="tables"
                    :fields="tablesFields"
                  >
                    <template #cell(table_number)="data">
                      <h5>#{{ data.value }}</h5>
                    </template>
                    <template #cell(count)="data">
                      <h5>{{ data.value }}</h5>
                    </template>
                    <template #cell(total)="data">
                      <h5>{{ `$${data.value}` }}</h5>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Item Types">
                  <div
                    v-if="
                      orders &&
                      orders.providers &&
                      orders.providers.totalSeries &&
                      orders.providers.totalSeries.length >= 1
                    "
                    class="d-flex flex-wrap justify-content-around"
                  >
                    <vue-apex-charts
                      type="donut"
                      height="500px"
                      width="100%"
                      class="my-2"
                      :options="donutTotalChartOptions"
                      :series="orders.providers.totalSeries"
                    />
                    <vue-apex-charts
                      type="donut"
                      height="500px"
                      width="100%"
                      class="my-2"
                      :options="donutCountChartOptions"
                      :series="orders.providers.countSeries"
                    />
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </b-tab>
      <b-tab title="Per Hour">
        <b-card v-if="loadingLive" class="card-zero-padding">
          <div class="d-flex justify-content-center flex-column align-items-center m-3">
            <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            <div class="m-2">Loading Analytics, Please wait...</div>
          </div>
        </b-card>
        <b-card v-else class="card-zero-padding">
          <b-card-body class="p-0">
            <statistic-card-with-area-chart
              v-if="
                perHourStatistics &&
                perHourStatistics.series[0].data &&
                perHourStatistics.series[0].data.length > 0
              "
              icon="UsersIcon"
              :statistic="kFormatter(perHourStatistics.count)"
              :statistic2="'$' + kFormatter(perHourStatistics.total)"
              :statistic3="
                '$' + (perHourStatistics.total / perHourStatistics.count).toFixed(2)
              "
              statistic-title="COMPLETED ORDERS"
              statistic-title2="TOTAL SALES"
              statistic-title3="AOV"
              :chart-data="perHourStatistics.series"
              :chart-options="chartOptionsPerHour"
            />
          </b-card-body>
        </b-card>
      </b-tab>
      <b-tab title="Per Week">
        <b-card v-if="loadingLive" class="card-zero-padding">
          <div class="d-flex justify-content-center flex-column align-items-center m-3">
            <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            <div class="m-2">Loading Analytics, Please wait...</div>
          </div>
        </b-card>
        <b-card v-else class="card-zero-padding">
          <b-card-body class="p-0">
            <statistic-card-with-area-chart
              v-if="
                perWeekStatistics &&
                perWeekStatistics.series[0] &&
                perWeekStatistics.series[0].data.length > 0
              "
              icon="UsersIcon"
              :statistic="kFormatter(perWeekStatistics.count)"
              :statistic2="'$' + kFormatter(perWeekStatistics.total)"
              :statistic3="
                '$' + perWeekStatistics.count === 0
                  ? 0
                  : (perWeekStatistics.total / perWeekStatistics.count).toFixed(2)
              "
              statistic-title="COMPLETED ORDERS"
              statistic-title2="TOTAL SALES"
              statistic-title3="AOV"
              :chart-data="perWeekStatistics.series"
              :chart-options="chartOptions"
            />
          </b-card-body>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<style scoped></style>
<style>
@media all and (min-width: 550px) {
  .card-zero-padding .card-body {
    padding: 20px;
  }
}
.card-zero-padding .card-body {
  padding: 3px;
}
.dropdown-menu {
  max-height: 420px;
  overflow-y: auto;
}
</style>
<script>
/* eslint-disable no-console */
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BTable,
  BTabs,
  BTab,
  VBModal,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from '@/layouts/components/StatisticCardWithAreaChart.vue'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import EcommerceStatistics from './Statistics.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BTable,
    BTabs,
    BTab,
    VueApexCharts,
    EcommerceStatistics,
    StatisticCardWithAreaChart,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      donutCountChartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)} Items`
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: 'By Count',
        },
      },
      donutTotalChartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `$${parseInt(val)}`
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: 'By Revenue',
        },
      },
      orders: {},
      chartOptions: {
        grid: {
          show: true,
          padding: {
            left: 20,
            right: 20,
            top: 40,
            bottom: 60,
          },
        },
        colors: ['#ffe700', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          id: 'orders-analytics',
          toolbar: {
            show: true,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'string',
          lines: {
            show: true,
          },
          style: {
            colors: $themeColors.primary,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            show: true,
            style: {
              colors: $themeColors.primary,
            },
          },
        },
        yaxis: [
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
          },
          x: {
            show: true,
          },
          y: [
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `${y.toFixed(0)} orders`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
          ],
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: -10,
        },
        theme: {
          monochrome: {
            enabled: false,
            color: $themeColors.primary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      chartOptionsPerHour: {
        grid: {
          show: true,
          padding: {
            left: 20,
            right: 20,
            top: 40,
            bottom: 60,
          },
        },
        colors: ['#ffe700', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          id: 'orders-analytics',
          toolbar: {
            show: true,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'string',
          lines: {
            show: true,
          },
          style: {
            colors: $themeColors.primary,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            show: true,
            style: {
              colors: $themeColors.primary,
            },
          },
        },
        yaxis: [
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
          },
          x: {
            show: true,
          },
          y: [
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `${y.toFixed(0)} orders`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
          ],
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: -10,
        },
        theme: {
          monochrome: {
            enabled: false,
            color: $themeColors.primary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      loadingAnalytics: false,
      day: 0,
      itemsFields: [
        {
          key: 'name',
          label: 'Item Name',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Orders',
          sortable: true,
        },
        { key: 'total', label: 'Sales', sortable: true },
      ],
      tables: [],
      tablesFields: [
        {
          key: 'table_number',
          label: 'Table',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Orders',
          sortable: true,
        },
        { key: 'total', label: 'Sales', sortable: true },
      ],
      customers: [],
      customersFields: [
        {
          key: 'full_name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Mobile',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Orders',
          sortable: true,
        },
        { key: 'total', label: 'Sales', sortable: true },
      ],
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Items',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],
      perWeekStatistics: {
        series: { name: '', data: [] },
      },
      perHourStatistics: {
        series: { name: '', data: [] },
      },
      loadingLive: true,
    }
  },
  computed: {
    // eslint-disable-next-line object-shorthand,func-names
    dayString: function () {
      if (this.day === 0) {
        return 'today'
      }
      return this.day === 1 ? 'yesterday' : `last ${this.day} days`
    },
  },
  async created() {
    /*    const This = this
    setInterval(() => {
      console.log(This.chartOptions.xaxis.categories)
    }, 2000) */

    await this.ordersAnalytics(this.day)
    await this.liveAnalytics()
  },
  methods: {
    kFormatter,
    async ordersAnalytics(days) {
      try {
        this.loadingAnalytics = true
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`analytics/dinein/days/${days}?dinein=true`)
        response = response.data
        if (response.type === 'success') {
          // this.chartOptions.labels = response.orders.labels
          this.orders.count = response.orders.count
          this.orders.total = response.orders.total
          this.orders.series = response.orders.series

          // Items
          this.orders.items = Object.values(response.orders.rawItems.series).sort(this.compare)

          // Customers
          this.customers = Object.values(response.customers.series).sort(this.compare)

          // Tables
          this.tables = Object.values(response.tables.series).sort(this.compare)

          // Statistics
          this.statisticsItems[2].title = kFormatter(this.orders.items.length)
          this.statisticsItems[0].title = kFormatter(this.orders.count)
          this.statisticsItems[3].title = kFormatter(this.orders.total)
          this.statisticsItems[1].title = kFormatter(this.customers.length)

          // Item Types - Food/Drink
          this.orders.providers = {}
          this.donutTotalChartOptions.labels = Object.keys(response.orders.itemType)
          this.donutCountChartOptions.labels = Object.keys(response.orders.itemType)
          this.orders.providers.totalSeries = Object.values(response.orders.itemType).map(
            itemType => itemType.total
          )
          this.orders.providers.countSeries = Object.values(response.orders.itemType).map(
            itemType => itemType.count
          )
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.loadingAnalytics = false
      }
    },
    async liveAnalytics() {
      try {
        this.loadingLive = true
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`analytics/dinein/live`)
        response = response.data
        if (response.type === 'success') {
          const perWeek = response.perWeekStatistics

          // Series data
          this.perWeekStatistics.series = [
            {
              name: 'Orders count',
              data: [],
            },
            {
              name: 'Total Orders Value',
              data: [],
            } /*
            {
              name: 'Min. order value',
              data: [],
            },
            {
              name: 'Max. order value',
              data: [],
            }, */,
            {
              name: 'Avg. order value',
              data: [],
            },
          ]
          this.chartOptions.labels = []
          Object.values(perWeek.series).forEach(day => {
            // Series labels
            this.chartOptions.labels.push(day.date)

            this.perWeekStatistics.series[0].data.push(day.count)
            this.perWeekStatistics.series[1].data.push(day.total)
            // this.perWeekStatistics.series[2].data.push(day.min)
            // this.perWeekStatistics.series[3].data.push(day.max)
            this.perWeekStatistics.series[2].data.push(
              day.count === 0 ? 0 : (day.total / day.count).toFixed(2)
            )
          })

          // Total revenue of the week
          this.perWeekStatistics.total = perWeek.total
          // Total orders or the week
          this.perWeekStatistics.count = perWeek.count

          // Per Hour Analytics
          const perHour = response.perHourStatistics

          // Series data
          this.perHourStatistics.series = [
            {
              name: 'Orders count',
              data: [],
            },
            {
              name: 'Total Orders Value',
              data: [],
            },
            {
              name: 'Avg. order value',
              data: [],
            },
          ]
          this.chartOptionsPerHour.labels = []
          Object.values(perHour.series).forEach(day => {
            // Series labels
            this.chartOptions.labels.push(day.hour)

            this.perHourStatistics.series[0].data.push(day.count)
            this.perHourStatistics.series[1].data.push(day.total)
            // this.perHourStatistics.series[2].data.push(day.min)
            // this.perHourStatistics.series[3].data.push(day.max)
            this.perHourStatistics.series[2].data.push(
              day.count === 0 ? 0 : (day.total / day.count).toFixed(2)
            )
          })

          // Total revenue of the hour
          this.perHourStatistics.total = perHour.total
          // Total orders or the hour
          this.perHourStatistics.count = perHour.count
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.loadingLive = false
      }
    },
    compare(a, b) {
      if (a.total > b.total) {
        return -1
      }
      if (a.total < b.total) {
        return 1
      }
      return 0
    },
  },
}
</script>
