<template>
  <b-row v-if="data">
    <b-col v-for="(serverReport, serverName) in data" :key="serverName" xl="3" sm="6">
      <b-card>
        <h5 class="text-capitalize text-center">{{ serverName }}</h5>
        <div class="d-flex justify-content-between">
          <span>Orders</span><span>{{ serverReport.count }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Total</span><span>${{ serverReport.total / 100 }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Voided Orders</span><span>{{ serverReport.voidedCount }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Voided Total</span><span>${{ serverReport.voidedTotal / 100 }}</span>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
