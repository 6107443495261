<template>
  <b-row>
    <div
      v-if="items[0] && newOrderDialog"
      class="position-fixed d-flex justify-content-between flex-column p-5 dark-layout"
      style="
        left: 0;
        z-index: 999999999999999;
        right: 0;
        bottom: 0;
        top: 0;
        background: #12181f;
      "
    >
      <div class="d-flex justify-content-between">
        <b-avatar
          size="100px"
          :src="require('@/assets/images/logo/' + items[0].provider + '-icon-large.svg')"
        />
        <h2
          class=""
          style="
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #3b4253;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
          "
          @click="hideNewOrderDialog"
        >
          x
        </h2>
      </div>

      <div>
        <div class="font-large-3 font-weight-bolder h1">
          {{ items[0].full_name }} just ordered
        </div>
        <div class="font-large-3 font-weight-bolder h1">{{ items[0].totalItems }} items</div>
        <div
          class="font-medium-5 text-white-50"
          style="
            max-width: 450px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ (items[0].items || []).map(item => item.name).join(', ') }}
        </div>
        <div
          class="font-large-1 h1 cursor-pointer user-select-none"
          style="
            max-width: 350px;
            white-space: nowrap;
            text-decoration: underline;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          @click="
            hideNewOrderDialog()
            order = items[0]
            orderDetailsDialog = true
          "
        >
          View order ->
        </div>
      </div>

      <div>
        <b-button size="xl" variant="primary" @click="hideNewOrderDialog"> Got it </b-button>
      </div>
    </div>
    <div class="d-flex justify-content-around flex-wrap col-12">
      <b-col md="2" sm="4" class="my-1" hidden>
        <b-form-group class="">
          <label class="d-inline-block mr-1">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group>
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-50 d-inline"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" :disabled="!sortBy" class="w-25 d-inline">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="5" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="filterInput"
          class=""
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </div>
    <b-col class="dense" cols="12">
      <b-table
        hover
        responsive
        show-empty
        :borderless="true"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        @row-clicked="onRowClicked"
      >
        <template #cell(id)="data">
          <div
            class="font-weight-bold text-dark"
            style="text-overflow: ellipsis; overflow: hidden"
          >
            #{{ data.item.displayId.slice(-5) || data.value.slice(-5) }}
          </div>
        </template>

        <template #cell(table_id)="data">
          <div
            class="font-weight-bold text-dark ml-1"
            style="text-overflow: ellipsis; overflow: hidden"
          >
            {{ data.value }}
          </div>
        </template>

        <template #cell(full_name)="data">
          <div class="text-nowrap overflow-hidden" style="max-width: 45vw">
            <div
              class="font-weight-bolder text-capitalize full_name text-primary"
              style="text-overflow: ellipsis; overflow: hidden"
            >
              {{ data.value }}
            </div>
            <div
              class="font-weight-bold text-dark order_id"
              style="text-overflow: ellipsis; overflow: hidden"
            >
              {{ data.item.phone }}
            </div>
          </div>
        </template>

        <template #cell(placed)="data">
          <h5>{{ data.value }}</h5>
        </template>

        <template #cell(status)="data">
          <div
            class="text-center text-nowrap d-flex flex-row-reverse justify-content-end align-items-center"
          >
            <div>
              <b-badge pill :variant="status[1][data.value]" class="mr-1">
                {{ status[0][data.value] }}
              </b-badge>
            </div>
          </div>
        </template>

        <template #cell(total)="data">
          <h5>{{ data.item.currencyCode || '$' }}{{ `${data.value}` }}</h5>
        </template>

        <template #cell(payment_method)="data">
          <div class="text-center text-nowrap">
            <b-badge pill :variant="paymentStatus[1][data.value]">
              {{ paymentStatus[0][data.value] }}
            </b-badge>
          </div>
        </template>

        <template #empty="">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <h3 v-if="!loadingOrders" class="m-4">No orders are available...</h3>
          </div>
        </template>
        <template #emptyfiltered="scope">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <h3 v-if="!loadingOrders" class="m-4">
              {{ scope.emptyFilteredText }}
            </h3>
          </div>
        </template>
      </b-table>
    </b-col>
    <div
      v-if="loadingOrders"
      class="d-flex justify-content-center flex-column align-items-center w-100 m-3"
    >
      <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
      <div class="m-2">Loading Orders, Please wait...</div>
    </div>
    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        size="sm"
        class="my-0 text-center d-flex justify-content-center"
      />
    </b-col>

    <DeliveryOrderDetailsPopup
      :order="order"
      :is-delivery="false"
      :order-details-dialog="orderDetailsDialog"
      @close="orderDetailsDialog = false"
    />
  </b-row>
</template>
<style></style>
<script>
/* eslint-disable no-console,no-param-reassign,no-underscore-dangle */
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBModal,
} from 'bootstrap-vue'
import DeliveryOrderDetailsPopup from '@/layouts/components/DeliveryOrderDetailsPopup.vue'
import Ripple from 'vue-ripple-directive'
import { AsYouType } from 'libphonenumber-js'

// Fix up prefixing
window.AudioContext = window.AudioContext || window.webkitAudioContext

export default {
  components: {
    BSpinner,
    BBadge,
    BAvatar,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    DeliveryOrderDetailsPopup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'full_name',
          label: 'Customer',
          sortable: true,
        },
        {
          key: 'id',
          label: '#ID',
          sortable: true,
        },
        {
          key: 'table_id',
          label: 'Table',
          sortable: true,
        },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'placed', label: 'Placed', sortable: true },
        { key: 'total', label: 'Total', sortable: true },
        { key: 'payment_method', label: 'Payment', sortable: true },
      ],
      items: [],
      status: [
        {
          placed: 'Placed',
          confirmed: 'Confirmed',
          completed: 'completed',
          cancelled: 'Cancelled',
        },
        {
          placed: 'light-primary',
          confirmed: 'light-warning',
          completed: 'light-success',
          cancelled: 'light-danger',
        },
      ],
      paymentStatus: [
        {
          1: 'Paid by CC',
          2: ' Cash',
        },
        {
          1: 'success',
          2: 'danger',
        },
      ],
      loadingOrders: true,
      orderDetailsDialog: false,
      newOrderDialog: false,
      order: {
        items: [],
      },
      lastUpdate: new Date(new Date().getTime() - 200 * 60 * 60 * 1000).toISOString(),
      keySoundBuffer: undefined,
      context: new AudioContext(),
      source: false,
      reprinting: false,
      loadingButtons: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    this.source = false
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  async created() {
    await this.getOrders()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onRowClicked(item, index, event) {
      // eslint-disable-next-line no-underscore-dangle,no-param-reassign
      this.order = item
      this.orderDetailsDialog = !this.orderDetailsDialog
    },
    hideNewOrderDialog() {
      this.newOrderDialog = false
      if (this.source) {
        this.source.stop(0)
        this.source = false
      }
    },
    unimplemented(item) {
      this.$bvToast.toast('This is not implemented yet', {
        title: item,
        variant: 'warning',
        solid: true,
      })
    },
    getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    async getOrders(hideRefreshing) {
      if (!hideRefreshing) this.loadingOrders = true
      try {
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`orders?dinein=true&time=${this.lastUpdate}`)
        response = response.data
        const { data } = response.orders[0]
        if (response.type === 'success' && response.orders && data.length > 0) {
          data.sort(this.compare)
          data.filter(order => {
            if (!order.provider || !order.store) {
              console.error(order)
              return false
            }
            return true
          })
          const newOrders = data.map(
            /**
             *
             * @param {Object} order
             * @returns
             * {{delivery: *, placed: string, scheduled: *, payment_method: *, delivery_instructions: *, tax: *, store: {}, deliver_by: (string|string), table_id: *, total, full_name: *, provider: *, phone: string, subtotal: *, fullOrder: *, tip, _id: *, id, displayId, items, status: (number)}}
             */
            order => {
              if (order.placed > this.lastUpdate) this.lastUpdate = order.placed
              const orders = {
                // eslint-disable-next-line no-underscore-dangle
                _id: order._id,
                provider: order.provider,
                status: order.status,
                full_name: order.full_name,
                placed: new Date(order.placed).toString().slice(4, 21),
                deliver_by: order.is_pickup
                  ? 'Pickup'
                  : new Date(order.deliver_by).toString().slice(16, 21),
                id: order.id,
                note: order.note,
                table_id: order.table_number,
                payment_method:
                  order.payment_method && order.payment_method.toLowerCase().includes('cash')
                    ? 2
                    : 1,
                scheduled: order.scheduled,
                delivery_instructions: order.delivery_instructions,
                subtotal: order.subtotal,
                tax: order.tax,
                tip: order.tip,
                total: order.total,
                phone: new AsYouType('US').input(`${order.phone}`),
                displayId: order.displayId || order.id,
                store: order.store || {},
                items: order.items,
                fullOrder: order.fullOrder,
                delivery: order.delivery,
              }
              if (orders.items) {
                orders.totalItems = orders.items.reduce(
                  (total, current) => total + current.quantity,
                  0
                )
              }
              // console.log(orders.totalItems)
              return orders
            }
          )
          if (newOrders.length > 0) {
            if (this.items.length > 0) {
              if (!this.source)
                this.source = this.playSound(this.keySoundBuffer, 0, 1, this.context)
              this.items = newOrders.concat(this.items)
              this.newOrderDialog = true
            } else this.items = newOrders
          }
          this.totalRows = this.items.length
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        } else if (data && data.length !== 0) {
          console.error(response)
          this.$bvToast.toast('Something went wrong', {
            title: 'Error!',
            variant: 'info',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      }
      this.loadingOrders = false
    },
    /**
     * @param {String} a.placed
     * @param {String} b.placed
     * @returns {number}
     */
    compare(a, b) {
      if (a.placed > b.placed) {
        return -1
      }
      if (a.placed < b.placed) {
        return 1
      }
      return 0
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
