<template>
  <div>
    <!-- Header: For Each Integration -->
    <div
      v-for="integration of integrations"
      :key="integration.value"
      class="d-flex flex-column mb-4"
    >
      <div class="d-flex align-items-center justify-content-between" style="max-width: 400px">
        <div class="d-flex align-items-center">
          <b-avatar
            v-if="integration.png"
            size="lg"
            :src="require(`@/assets/images/logo/${integration.value}-icon-small.png`)"
          />
          <b-avatar
            v-else
            size="lg"
            :src="require(`@/assets/images/logo/${integration.value}-icon-small.svg`)"
          />
          <h3 class="d-inline mx-2 mb-0">
            {{ integration.title }}
          </h3>
        </div>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form ref="form" class="mt-1 repeater-form">
        <!-- Row Loop -->
        <b-row
          v-for="(account, index) of (userData.integrations || {})[integration.value] || []"
          :key="index"
          ref="row"
        >
          <!-- Field: Username -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Username" label-for="username">
              <b-form-input
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index].username
                "
              />
            </b-form-group>
          </b-col>

          <!-- Field: Password -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Password" label-for="password">
              <b-form-input
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index].password
                "
              />
            </b-form-group>
          </b-col>

          <!-- Field: API Key -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="API Key" label-for="api_key">
              <b-form-input
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index].api_key
                "
              />
            </b-form-group>
          </b-col>

          <!-- Field: Producer Location Key -->
          <b-col v-if="integration.value === 'relay'" cols="12" md="6" lg="4">
            <b-form-group label="Producer Location Key" label-for="producer_location_key">
              <b-form-input
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index]
                    .producer_location_key
                "
              />
            </b-form-group>
          </b-col>

          <!-- Field: Account Name URL -->
          <b-col v-if="integration.value === 'omnimenu'" cols="12" md="6" lg="4">
            <b-form-group
              label="Account Name URL"
              :label-for="`account-name-url-${integration.value}`"
            >
              <b-form-input
                :id="`account-name-url-${integration.value}`"
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index]
                    .account_name_url
                "
              />
            </b-form-group>
          </b-col>

          <!-- Field: Outlet Name URL -->
          <b-col v-if="integration.value === 'omnimenu'" cols="12" md="6" lg="4">
            <b-form-group
              label="Outlet Name URL"
              :label-for="`outlet-name-url-${integration.value}`"
            >
              <b-form-input
                :id="`outlet-name-url-${integration.value}`"
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index]
                    .outlet_name_url
                "
              />
            </b-form-group>
          </b-col>

          <!-- Field: Pin -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Pin" label-for="pin">
              <b-form-input
                v-model="((userData.integrations || {})[integration.value] || [])[index].pin"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Start Time -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Start Time" label-for="startTime">
              <flat-pickr
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index].startTime
                "
                class="form-control"
                :config="{
                  dateFormat: 'H:i',
                  enableTime: true,
                  noCalendar: true,
                  defaultHour: 11,
                  defaultMinute: 0,
                  time_24hr: true,
                  mode: 'single',
                  defaultDate: '11:00',
                }"
                placeholder="HH:MM"
                @on-close="
                  ;((userData.integrations || {})[integration.value] || [])[
                    index
                  ].startTime = $event
                "
              />
            </b-form-group>
          </b-col>

          <!-- Field: End Time -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="End Time" label-for="endTime">
              <flat-pickr
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index].endTime
                "
                class="form-control"
                :config="{
                  dateFormat: 'H:i',
                  enableTime: true,
                  noCalendar: true,
                  defaultHour: 11,
                  defaultMinute: 0,
                  time_24hr: true,
                  mode: 'single',
                  defaultDate: '23:00',
                }"
                placeholder="HH:MM"
                @on-close="
                  ;((userData.integrations || {})[integration.value] || [])[
                    index
                  ].endTime = $event
                "
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col cols="12" md="6" lg="4">
            <div class="d-flex flex-wrap justify-content-around align-items-center">
              <b-form-checkbox
                v-model="
                  ((userData.integrations || {})[integration.value] || [])[index].status
                "
                title="Status"
                class="custom-control-primary d-inline mb-1"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                size="sm"
                class="mb-1"
                @click="removeItem(integration.value, index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
                <span>Delete</span>
              </b-button>
            </div>
            <!-- Status -->
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="repeateAgain(integration.value)"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add New</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="dark"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="editIntegration(integration.value)"
            >
              <b-spinner v-if="editing" style="margin: 1px 3px" small />
              Save Changes
            </b-button>
            <b-button
              v-if="false"
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BAvatar,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    BButton,
    BAvatar,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
      'Hindi',
    ]
    const integrations = [
      { value: 'omnimenu', title: 'Omnimenu', png: true },
      { value: 'tryotter', title: 'TryOtter', png: true },
      { value: 'relay', title: 'Relay' },
      { value: 'grubhub', title: 'Grubhub' },
      { value: 'doordash', title: 'Doordash' },
      { value: 'ubereats', title: 'Ubereats' },
      { value: 'postmates', title: 'Postmates' },
    ]
    const editing = ref(false)
    const toast = useToast()

    const showToast = (title, icon, variant, text) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    }

    const editIntegration = integration => {
      editing.value = true
      // eslint-disable-next-line prefer-destructuring
      const userData = props.userData

      const postData = {
        integration,
        data: (userData.integrations || {})[integration] || [],
        // username: ((userData.integrations || {})[integration] || {}).username,
        // password: ((userData.integrations || {})[integration] || {}).password,
        // api_key: ((userData.integrations || {})[integration] || {}).api_key,
        // producer_location_key: ((userData.integrations || {})[integration] || {})
        //   .producer_location_key,
        // status: ((userData.integrations || {})[integration] || {}).status,
        // pin: ((userData.integrations || {})[integration] || {}).pin,
        // startTime: ((userData.integrations || {})[integration] || {}).startTime,
        // endTime: ((userData.integrations || {})[integration] || {}).endTime,
      }
      store
        .dispatch('app-user/editIntegration', {
          id: /* testing */ router.route.value.query.id,
          postData,
        })
        .then(response => {
          if (response.data && response.data.type === 'success')
            showToast('Successfully Updated', 'CheckIcon', 'success')
          else showToast('Error!', 'AlertTriangleIcon', 'danger', response.data.display)
        })
        .catch(error => {
          showToast('Error!', 'AlertTriangleIcon', 'danger', error.message)
        })
        .finally(() => {
          editing.value = false
        })
    }

    const genderOptions = [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ]

    const contactOptionsOptions = ['Email', 'Message', 'Phone']

    return {
      languageOptions,
      genderOptions,
      contactOptionsOptions,
      integrations,
      editIntegration,
      editing,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain(integration) {
      // eslint-disable-next-line no-console
      console.log(integration)
      if (this.userData.integrations[integration]) {
        // TODO fix mutating props
        // eslint-disable-next-line vue/no-mutating-props
        this.userData.integrations[integration].push({
          status: false,
          api_key: null,
          endDate: null,
          password: null,
          pin: null,
          startDate: null,
          username: null,
        })
      }
      console.log(this.userData.integrations)
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(integration, index) {
      // TODO fix mutating props
      // eslint-disable-next-line vue/no-mutating-props
      this.userData.integrations[integration].splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(document.querySelectorAll('form')[3].scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.badge-secondary {
  background-color: #000000 !important;
}
</style>
