<template>
  <div>
    <analytics-delivery v-show="deliveryOrDineIn === 'delivery'" />
    <analytics-dine-in v-show="deliveryOrDineIn === 'dinein'" />
  </div>
</template>
<style scoped></style>
<script>
import { mapState } from 'vuex'
import AnalyticsDelivery from './AnalyticsDelivery.vue'
import AnalyticsDineIn from './AnalyticsDineIn.vue'

export default {
  components: {
    AnalyticsDelivery,
    AnalyticsDineIn,
  },
  data() {
    return {}
  },
  computed: mapState({
    deliveryOrDineIn: state => state.deliveryOrDineIn.deliveryOrDineIn,
  }),
  beforeDestroy() {},
  mounted() {},
  methods: {},
}
</script>
