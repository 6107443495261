<template>
  <b-card no-body class="border-primary">
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">Staff Pins</h5>
      <!--      <b-badge variant="light-primary">-->
      <!--        {{ userData.currentPlan }}-->
      <!--      </b-badge>-->
      <!--      <small class="text-muted w-100">{{-->
      <!--        new Date(userData.planValidity).toDateString()-->
      <!--      }}</small>-->
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li
          v-for="staffUser of userData.staff"
          :key="staffUser.pin"
          class="d-flex justify-content-between"
        >
          <span class="align-middle">{{ staffUser.name }}</span>
          <span class="align-middle">{{ staffUser.pin }}</span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    // BBadge,
    // BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style></style>
