<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <div class="d-flex justify-content-around flex-wrap align-items-center">
        <div class="d-flex flex-column align-items-center" style="min-width: 170px">
          <span>{{ statisticTitle }}</span>
          <div class="d-inline">
            <h2 class="mb-25 font-weight-bolder d-inline">
              {{ statistic }}
            </h2>
          </div>
        </div>
        <div
          v-if="statisticTitle2"
          class="d-flex flex-column align-items-center"
          style="min-width: 170px"
        >
          <span>{{ statisticTitle2 }}</span>
          <div class="d-inline">
            <h2 class="mb-25 font-weight-bolder d-inline">
              {{ statistic2 }}
            </h2>
          </div>
        </div>
        <div
          v-if="statisticTitle3"
          class="d-flex flex-column align-items-center"
          style="min-width: 170px"
        >
          <span>{{ statisticTitle3 }}</span>
          <div class="d-inline">
            <h2 class="mb-25 font-weight-bolder d-inline">
              {{ statistic3 }}
            </h2>
          </div>
        </div>
      </div>
    </b-card-body>

    <vue-apex-charts
      type="area"
      height="450px"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    />
  </b-card>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { areaChartOptions } from '@core/components/statistics-cards/chartOptions'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statistic2: {
      type: [Number, String],
      required: true,
    },
    statistic3: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticTitle2: {
      type: String,
      default: undefined,
    },
    statisticTitle3: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions))
        options.theme.monochrome.color = $themeColors[this.color]
        return options
      }
      return this.chartOptions
    },
  },
}
</script>
