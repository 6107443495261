<template>
  <div>
    <!-- Header: For Each Integration -->
    <div class="d-flex flex-column mb-4">
      <div class="d-flex align-items-center justify-content-between" style="max-width: 400px">
        <div class="d-flex align-items-center">
          <b-avatar size="lg" :src="require(`@/assets/images/logo/vuexy-logo.png`)" />
          <h3 class="d-inline mx-2 mb-0">Printers</h3>
        </div>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form ref="form" class="mt-1 repeater-form">
        <!-- Row Loop -->
        <div v-for="(printer, index) of printers || []" :key="index" ref="row">
          <h4 class="mt-3">Printer {{ index + 1 }}</h4>
          <b-row>
            <!-- Field: Label -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Label" label-for="label">
                <b-form-input v-model="printer.label" />
              </b-form-group>
            </b-col>
            <!-- Field: Serial -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Serial" label-for="serial">
                <b-form-input v-model="printer.serial" />
              </b-form-group>
            </b-col>

            <!-- Field: Password -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Password" label-for="password">
                <b-form-input v-model="printer.password" />
              </b-form-group>
            </b-col>

            <!-- Field: Dine In -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Dine In" :label-for="'dinein' + index">
                <b-form-checkbox
                  :id="'dinein' + index"
                  v-model="printer.dineIn"
                  title="Dine In"
                  class="custom-control-primary d-inline mb-1"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- Field: Delivery -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Delivery" :label-for="'delivery' + index">
                <b-form-checkbox
                  :id="'delivery' + index"
                  v-model="printer.delivery"
                  title="Delivery"
                  class="custom-control-primary d-inline mb-1"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- Field: Kitchen -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Kitchen" :label-for="'kitchen' + index">
                <b-form-checkbox
                  :id="'kitchen' + index"
                  v-model="printer.kitchen"
                  title="Kitchen"
                  class="custom-control-primary d-inline mb-1"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- Field: Bar -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Bar" :label-for="'bar' + index">
                <b-form-checkbox
                  :id="'bar' + index"
                  v-model="printer.bar"
                  title="Bar"
                  class="custom-control-primary d-inline mb-1"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- Field: Customer -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Customer" :label-for="'customer' + index">
                <b-form-checkbox
                  :id="'customer' + index"
                  v-model="printer.customer"
                  title="Customer"
                  class="custom-control-primary d-inline mb-1"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <br />
            <!-- Remove Button -->
            <b-col cols="12" md="6" lg="4">
              <div class="d-flex flex-wrap justify-content-between align-items-center">
                <!-- Field: Enabled -->
                <b-form-group label="Enabled" label-for="enabled">
                  <b-form-checkbox
                    id="enabled"
                    v-model="printer.enabled"
                    title="Enabled"
                    class="custom-control-primary d-inline mb-1"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  class="mb-1"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </div>
              <!-- Status -->
            </b-col>
          </b-row>
        </div>

        <b-row class="mt-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="repeateAgain()"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add New</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="dark"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="editPrinters()"
            >
              <b-spinner v-if="editing" style="margin: 1px 3px" small />
              Save Changes
            </b-button>
            <b-button
              v-if="false"
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BAvatar,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BAvatar,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const editing = ref(false)
    const toast = useToast()
    const router = useRouter()

    const showToast = (title, icon, variant, text) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    }

    const printers = computed(() => {
      return (
        props.userData.printers || [
          {
            enabled: false,
            serial: '',
            password: '',
            label: '',
            dineIn: false,
            delivery: false,
            kitchen: false,
            bar: false,
            customer: false,
          },
        ]
      )
    })

    const editPrinters = () => {
      editing.value = true
      // eslint-disable-next-line prefer-destructuring
      // const userData = props.userData

      const postData = {
        printers: printers.value || [],
      }
      store
        .dispatch('app-user/editPrinters', {
          id: /* testing */ router.route.value.query.id,
          postData,
        })
        .then(response => {
          if (response.data && response.data.type === 'success')
            showToast('Successfully Updated', 'CheckIcon', 'success')
          else showToast('Error!', 'AlertTriangleIcon', 'danger', response.data.display)
        })
        .catch(error => {
          showToast('Error!', 'AlertTriangleIcon', 'danger', error.message)
        })
        .finally(() => {
          editing.value = false
        })
    }

    return {
      editPrinters,
      editing,
      printers,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      // eslint-disable-next-line no-console
      console.log('repeateAgain')
      this.printers.push({
        enabled: false,
        serial: '',
        password: '',
        label: '',
        dineIn: false,
        delivery: false,
        kitchen: false,
        bar: false,
        customer: false,
      })
      // eslint-disable-next-line no-console
      console.log(this.printers)
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.printers.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(document.querySelectorAll('form')[3].scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.badge-secondary {
  background-color: #000000 !important;
}
</style>
