<template>
  <b-row>
    <div
      v-if="ordersList[0] && newOrderDialog"
      class="position-fixed d-flex justify-content-between flex-column p-5 dark-layout"
      style="
        left: 0;
        z-index: 999999999999999;
        right: 0;
        bottom: 0;
        top: 0;
        background: #12181f;
      "
    >
      <div class="d-flex justify-content-between">
        <!--        <b-avatar-->
        <!--          size="100px"-->
        <!--          :src="require('@/assets/images/logo/' + ordersList[0].provider + '-icon-large.svg')"-->
        <!--        />-->
        <h2
          class=""
          style="
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #3b4253;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
          "
          @click="hideNewOrderDialog"
        >
          x
        </h2>
      </div>

      <div>
        <div class="font-large-3 font-weight-bolder h1">
          {{ ordersList[0].full_name }} just ordered {{ ordersList[0].totalItems }} items
        </div>
        <div
          class="font-large-1 text-white-50"
          style="
            max-width: 70vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ (ordersList[0].items || []).map(item => item.name).join(', ') }}
        </div>
        <div
          class="font-large-1 h1 cursor-pointer user-select-none"
          style="
            max-width: 350px;
            white-space: nowrap;
            text-decoration: underline;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          @click="
            hideNewOrderDialog()
            order = ordersList[0]
            orderDetailsDialog = true
          "
        >
          View order ->
        </div>
      </div>

      <div>
        <b-button size="xl" variant="primary" @click="hideNewOrderDialog"> Got it </b-button>
      </div>
    </div>
    <b-col cols="12" class="dense">
      <b-table
        hover
        responsive
        show-empty
        :borderless="true"
        :per-page="perPage"
        :current-page="currentPage"
        :items="ordersList"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        @row-clicked="onRowClicked"
      >
        <template #cell(table)="data" class="hide-sm">
          <div class="text-nowrap overflow-hidden" style="max-width: 45vw">
            <div
              class="font-weight-bolder text-dark full_name"
              style="text-overflow: ellipsis; overflow: hidden"
            >
              {{ data.value }}
              <span
                class="font-weight-bolder font-medium-5"
                style="height: 1em; line-height: 1em"
              ></span>
            </div>
            <div
              class="font-weight-bold text-dark order_id"
              style="text-overflow: ellipsis; overflow: hidden"
            ></div>
          </div>
        </template>

        <template #cell(status)="data">
          <div class="h5 mb-0">{{ `${data.value}` }}</div>
        </template>

        <template #cell(server)="data">
          <div class="h5 mb-0">{{ `${data.value}` }}</div>
        </template>

        <template #cell(total)="data">
          <div class="h5 mb-0">
            {{ data.item.currencyCode || '$' }}{{ `${data.value / 100}` }}
          </div>
        </template>
        <template #cell(events)="data">
          <div class="h5 mb-0">
            {{ `${new Date(data.value[0].time).toString().substr(0, 24)}` }}
          </div>
        </template>

        <template #empty="">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <h3 v-if="!loadingOrders" class="m-4">No orders are available...</h3>
          </div>
        </template>
        <template #emptyfiltered="scope">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <h3 v-if="!loadingOrders" class="m-4">
              {{ scope.emptyFilteredText }}
            </h3>
          </div>
        </template>
      </b-table>
    </b-col>
    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="ordersList.length"
        :per-page="perPage"
        size="sm"
        class="my-0 text-center d-flex justify-content-center"
      />
    </b-col>

    <DeliveryOrderDetailsPopup
      :divide-by-hundred="true"
      :order="Object.assign(order, { totalItems: order.items.length })"
      :order-details-dialog="orderDetailsDialog"
      @close="orderDetailsDialog = false"
    />
  </b-row>
</template>
<style scoped></style>
<script>
/* eslint-disable no-console,no-param-reassign,no-underscore-dangle */
import { BButton, BCol, BRow, BTable, VBModal, BPagination } from 'bootstrap-vue'
import DeliveryOrderDetailsPopup from '@/layouts/components/DeliveryOrderDetailsPopup.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BPagination,
    DeliveryOrderDetailsPopup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  props: {
    ordersList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'table',
          label: 'Table',
          sortable: true,
        },
        {
          key: 'server',
          label: 'Server Name',
          sortable: true,
        },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'events', label: 'Placed', sortable: true },
        { key: 'total', label: 'Total', sortable: true },
      ],
      items: [],
      status: [
        {
          placed: 'Placed',
          confirmed: 'Confirmed',
          completed: 'Ready',
          cancelled: 'Cancelled',
          closed: 'Closed',
        },
        {
          placed: 'light-primary',
          confirmed: 'light-primary',
          completed: 'light-success',
          cancelled: 'light-danger',
          closed: 'dark',
        },
      ],
      deliveryStatus: [
        {
          1: 'Driver',
          2: 'Pickup',
          3: 'Void Delivery',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'outline-primary',
          2: 'outline-warning',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      spinner: {
        complete: false,
        cancel: false,
        reprintKitchen: false,
        reprintBar: false,
        reprintCustomer: false,
        close: false,
        adjust: false,
      },
      loadingOrders: false,
      orderDetailsDialog: false,
      newOrderDialog: false,
      order: {
        items: [],
      },
      lastUpdate: new Date(new Date().getTime() - 5 * 60 * 60 * 1000).toISOString(),
      checkOrderInterval: undefined,
      keySoundBuffer: undefined,
      context: new AudioContext(),
      source: false,
      loadingButtons: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    ordersList(newVal) {
      console.log(newVal)
      this.totalRows = newVal.length
      this.items = newVal
    },
  },
  beforeDestroy() {
    clearInterval(this.checkOrderInterval)
    this.source = false
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.ordersList.length
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onRowClicked(item, index, event) {
      // eslint-disable-next-line no-underscore-dangle,no-param-reassign
      this.order = item
      this.orderDetailsDialog = !this.orderDetailsDialog
    },
    hideNewOrderDialog() {
      this.newOrderDialog = false
      if (this.source) {
        this.source.stop(0)
        this.source = false
      }
    },
    unimplemented(item) {
      this.$bvToast.toast('This is not implemented yet', {
        title: item,
        variant: 'warning',
        solid: true,
      })
    },
    /**
     * @param {String} a.placed
     * @param {String} b.placed
     * @returns {number}
     */
    compare(a, b) {
      if (a.placed > b.placed) {
        return -1
      }
      if (a.placed < b.placed) {
        return 1
      }
      return 0
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
