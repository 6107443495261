<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'users' }"> User List </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Integrations -->
      <!--      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Staff Pin</span>
        </template>
        <user-edit-tab-pin :user-data="userData" class="mt-2 pt-75" />
      </b-tab>-->

      <!-- Tab: Printers -->
      <b-tab>
        <template #title>
          <feather-icon icon="PrinterIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Printers</span>
        </template>
        <!--        <user-edit-tab-integrations :user-data="userData" class="mt-2 pt-75" />-->
      </b-tab>

      <!-- Tab: Information -->
      <b-tab v-if="false">
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab v-if="false">
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
    <div v-else class="d-flex justify-content-center flex-column align-items-center w-100 m-3">
      <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
      <div class="m-2">Loading , Please wait...</div>
    </div>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BSpinner } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
// import UserEditTabPin from './UserEditTabPin.vue'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'

export default {
  components: {
    // UserEditTabPin,
    BTab,
    BSpinner,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  setup() {
    const userData = ref(null)
    const router = useRouter()

    const USER_APP_STORE_MODULE_NAME = 'staff-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('staff-user/fetchUser', { id: /* testing */ router.route.value.query.id })
      .then(response => {
        userData.value = response.data.user
        userData.value.planValidity = response.data.user.planValidity.slice(0, 10)
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style></style>
