<template>
  <b-row>
    <b-modal
      v-show="orderDetailsDialog && order"
      ref="myModalRef"
      ok-only
      scrollable
      ok-title="Close"
      size="default"
      title="Order Details"
      class=""
      @hide="closeModel"
    >
      <div
        class="font-weight-bolder text-center font-large-1 text-dark"
        style="font-family: Arial, sans-serif"
      >
        {{
          `${order.totalItems} ${order.totalItems > 1 ? 'items' : 'item'} for ` +
          (order.full_name || order.server || '').charAt(0).toUpperCase() +
          (order.full_name || order.server || '').slice(1)
        }}
      </div>
      <div
        class="font-weight-bolder text-center font-large-1 text-dark"
        style="font-family: Arial, sans-serif"
      >
        {{ order.phone }}
      </div>
      <h4 v-if="isDelivery" class="text-center">
        <span v-if="order.store && order.store.location" class="d-inline-block">
          • {{ (order.store || {}).location }}</span
        >
        <span class="d-inline-block">
          •
          {{
            (order.provider || '').charAt(0).toUpperCase() + (order.provider || '').slice(1)
          }}</span
        >
        <span class="text-nowrap">#{{ order.id + ' / ' + order.displayId }}</span>
      </h4>
      <h4 v-if="isDelivery && order.hotel" class="text-center">
        <span v-if="order.hotel.name" class="d-inline-block">
          Hotel - {{ (order.hotel || {}).name }}</span
        >
        •
        <span v-if="order.hotel.roomNumber" class="d-inline-block">
          Room - {{ order.hotel.roomNumber || '' }}</span
        >
      </h4>
      <h5
        v-if="isDelivery && order.printable_address && order.printable_address !== ''"
        class="d-flex flex-column justify-content-center text-center font-medium-2"
      >
        <br />
        <br />
        {{ order.printable_address }}
        <br />
      </h5>
      <h5
        v-if="
          isDelivery &&
          order.delivery_instructions &&
          order.delivery_instructions.trim() !== ''
        "
        class="text-center font-medium-2"
      >
        <span class="font-weight-bolder text-dark">Delivery instructions: </span
        ><span class="ml-1"> {{ order.delivery_instructions }}</span>
        <br />
      </h5>
      <h5
        v-if="
          isDelivery && order.deliveryInstructions && order.deliveryInstructions.trim() !== ''
        "
        class="text-center font-medium-2"
      >
        <span class="font-weight-bolder text-dark">Delivery instructions: </span
        ><span class="ml-1"> {{ order.deliveryInstructions }}</span>
        <br />
      </h5>
      <br />
      <h5 v-if="order.note && order.note.trim() !== ''" class="text-center font-medium-2">
        <span class="font-weight-bolder text-primary">Note: </span
        ><span class="ml-1"> {{ order.note }}</span>
      </h5>
      <div>
        <div class="items">
          <div v-for="(item, index) of order.items" :key="index" class="item">
            <div class="item-name d-flex align-items-start">
              <div class="item" style="width: 100%; margin-top: 8px">
                <h4 class="ml-2 text-dark d-flex justify-content-between">
                  <div class="d-flex align-items-start">
                    <span class="font-weight-bold mr-1"> {{ item.quantity }} X </span>
                    <span class="">{{ item.name }}</span>
                  </div>
                  <span class="ml-1"
                    >{{ order.currencyCode ? order.currencyCode + ' ' : '$'
                    }}{{ divideByHundred ? item.price / 100 : item.price }}</span
                  >
                </h4>
                <div
                  v-if="item.specialInstructions && item.specialInstructions.length > 0"
                  class="special-instructions ml-2"
                >
                  <div
                    v-for="(instruction, i) of item.specialInstructions"
                    :key="i"
                    class="special-instruction mb-1"
                  >
                    <span
                      v-if="instruction && instruction"
                      class="h6 font-medium-1 d-flex justify-content-between"
                    >
                      <span v-if="instruction.title"
                        ><span class="text-dark font-weight-bolder">•</span>
                        {{ instruction.title }}
                      </span>
                      <span v-if="instruction.price" class="ml-1"
                        >{{ order.currencyCode ? order.currencyCode + ' ' : '$'
                        }}{{
                          divideByHundred ? instruction.price / 100 : instruction.price
                        }}</span
                      >
                    </span>
                    <div v-if="instruction.subtitles" class="subtitles">
                      <div
                        v-for="(subtitle, k) of instruction.subtitles"
                        :key="k"
                        class="special-instruction d-flex justify-content-between"
                      >
                        <span v-if="subtitle && subtitle.title" class="h6">
                          <span class="text-dark font-weight-bolder">--</span>
                          {{ subtitle.quantity }}x {{ subtitle.title }}</span
                        >
                        <span v-if="subtitle && subtitle.price" class="h6 ml-1">
                          {{ order.currencyCode ? order.currencyCode + ' ' : '$'
                          }}{{ divideByHundred ? subtitle.price / 100 : subtitle.price }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <h5 v-if="item.note" class="specialInstructions ml-2 mt-1">
                  <span class="font-weight-bolder text-primary">Note: </span
                  ><span> {{ item.note }}</span>
                </h5>
                <h5 v-if="item.allergy && item.allergy.length > 0" class="allergy ml-2 mt-1">
                  <span class="font-weight-bolder text-warning">Allergy: </span
                  ><span> {{ item.allergy.map(allergy => allergy.name).join(', ') }}</span>
                </h5>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div class="totals font-medium-2 text-dark">
          <div class="total mb-1 d-flex justify-content-center" style="width: 100%">
            <span>Subtotal</span>
            <span class="line-spacer" />
            <span
              >{{ order.currencyCode ? order.currencyCode + ' ' : '$'
              }}{{
                order.subtotal
                  ? `${divideByHundred ? order.subtotal / 100 : order.subtotal.toFixed(2)}`
                  : '0'
              }}</span
            >
          </div>
          <div class="total mb-1 d-flex justify-content-center" style="width: 100%">
            <span>Tax</span>
            <span class="line-spacer" />
            <span
              >{{ order.currencyCode ? order.currencyCode + ' ' : '$'
              }}{{
                order.tax ? `${divideByHundred ? order.tax / 100 : order.tax.toFixed(2)}` : '0'
              }}</span
            >
          </div>
          <div
            v-if="order.tip"
            class="total mb-1 d-flex justify-content-center"
            style="width: 100%"
          >
            <span>Tip</span>
            <span class="line-spacer" />
            <span>
              {{ order.currencyCode ? order.currencyCode + ' ' : '$'
              }}{{ order.tip ? `${divideByHundred ? order.tip / 100 : order.tip}` : 0 }}</span
            >
          </div>
          <div
            v-if="order.service_fee"
            class="total mb-1 d-flex justify-content-center"
            style="width: 100%"
          >
            <span>Operating Fees</span>
            <span class="line-spacer" />
            <span>
              {{ order.currencyCode ? order.currencyCode + ' ' : '$'
              }}{{
                order.service_fee
                  ? `${divideByHundred ? order.service_fee / 100 : order.service_fee}`
                  : 0
              }}</span
            >
          </div>
          <div
            v-if="order.discount"
            class="total mb-1 d-flex justify-content-center"
            style="width: 100%"
          >
            <span>Discount</span>
            <span class="line-spacer" />
            <span>
              {{ order.currencyCode ? order.currencyCode + ' ' : '$'
              }}{{
                order.discount
                  ? `${divideByHundred ? order.discount / 100 : order.discount}`
                  : 0
              }}</span
            >
          </div>
          <div
            v-if="order.loyalty_point_using"
            class="total mb-1 d-flex justify-content-center"
            style="width: 100%"
          >
            <span>Used Loyalty Points</span>
            <span class="line-spacer" />
            <span> {{ order.loyalty_point_using.toFixed(1) }}</span>
          </div>
          <div
            v-if="order.discount_code"
            class="total mb-1 d-flex justify-content-center"
            style="width: 100%"
          >
            <span>Discount Coupon</span>
            <span class="line-spacer" />
            <span> {{ order.discount_code }}</span>
          </div>
          <div
            v-if="order.delivery_fee"
            class="total mb-1 d-flex justify-content-center"
            style="width: 100%"
          >
            <span>Delivery Fee</span>
            <span class="line-spacer" />
            <span>
              {{ order.currencyCode ? order.currencyCode + ' ' : '$'
              }}{{
                order.delivery_fee
                  ? `${divideByHundred ? order.delivery_fee / 100 : order.delivery_fee}`
                  : 0
              }}</span
            >
          </div>
          <div
            v-if="order.gratuity"
            class="total mb-1 d-flex justify-content-center"
            style="width: 100%"
          >
            <span>Gratuity</span>
            <span class="line-spacer" />
            <span>
              {{ order.currencyCode ? order.currencyCode + ' ' : '$'
              }}{{
                order.gratuity
                  ? `${divideByHundred ? order.gratuity / 100 : order.gratuity}`
                  : 0
              }}</span
            >
          </div>
          <div class="total mb-1 d-flex justify-content-center" style="width: 100%">
            <span>Total</span>
            <span class="line-spacer" />
            <span
              >{{ order.currencyCode ? order.currencyCode + ' ' : '$'
              }}{{
                order.total
                  ? divideByHundred
                    ? order.total / 100
                    : order.total.toFixed(2)
                  : '0'
              }}</span
            >
          </div>
        </div>
        <!--        v-if="order.full_name"-->
        <div class="p-1 d-flex justify-content-center">
          <b-button
            :disabled="reprinting"
            :variant="reprinting ? 'success' : 'warning'"
            @click="reprint(order.id)"
          >
            <b-spinner v-if="reprinting" small />
            <span class="ml-1">{{
              reprinting ? 'Requesting Reprint...' : 'Reprint Receipt'
            }}</span>
          </b-button>
        </div>

        <div />
        <div />
      </div>
    </b-modal>
  </b-row>
</template>
<style scoped></style>
<script>
/* eslint-disable no-console,no-param-reassign,no-underscore-dangle */
import { BButton, BRow, BSpinner, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// Fix up prefixing
window.AudioContext = window.AudioContext || window.webkitAudioContext

export default {
  components: {
    BSpinner,
    BRow,
    BButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    orderDetailsDialog: {
      type: Boolean,
      default: () => false,
    },
    divideByHundred: {
      type: Boolean,
      default: () => false,
    },
    isDelivery: {
      type: Boolean,
      default: () => true,
    },
    order: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },
  data() {
    return {
      reprinting: false,
    }
  },
  watch: {
    orderDetailsDialog(newVal) {
      if (newVal) {
        this.$refs.myModalRef.show()
      } else {
        this.$refs.myModalRef.hide()
      }
    },
  },
  methods: {
    async reprint(orderID) {
      try {
        this.reprinting = true
        const response = (await this.$http.get(`reprint-order?orderID=${orderID}`)).data
        console.log(response)
        if (response.printed) {
          this.$bvToast.toast('Reprint request submitted successfully', {
            title: 'Success!',
            variant: 'success',
            solid: true,
          })
        } else if (response.display) {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        } else {
          this.$bvToast.toast('something went wrong Error#904932', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.reprinting = false
      }
    },
    closeModel() {
      this.$emit('close', true)
    },
  },
}
</script>
