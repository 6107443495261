<template>
  <div>
    <div
      class="m-1 mb-3 d-flex justify-content-end"
      style="position: -webkit-sticky; position: sticky; top: 100px; z-index: 99"
    >
      <div class="" style="">
        <span>Show analytics for: </span>
        <span> {{ dayString }} </span>
        <b-dropdown
          id="days-selector"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="loadingAnalytics"
          text="Select Days"
          variant="primary"
          class="ml-2"
        >
          <b-dropdown-item
            v-for="(count, selectedDay) in 51"
            :key="selectedDay"
            :value="selectedDay"
            @click="
              ordersAnalytics(selectedDay)
              day = selectedDay
            "
          >
            {{
              selectedDay === 0
                ? 'today'
                : selectedDay === 1
                ? 'yesterday'
                : 'last ' + selectedDay + ' days'
            }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-card class="card-zero-padding">
      <div
        v-if="loadingAnalytics"
        class="d-flex justify-content-center flex-column align-items-center m-3"
      >
        <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
        <div class="m-2">Loading Analytics, Please wait...</div>
      </div>
      <b-card-body v-else class="p-0">
        <statistic-card-with-area-chart
          v-if="orders && orders.series && orders.series[0].data.length > 1"
          icon="UsersIcon"
          :statistic="kFormatter(orders.count)"
          :statistic2="'$' + orders.total"
          :statistic3="'$' + (orders.total / orders.count).toFixed(2)"
          statistic-title="COMPLETED ORDERS"
          statistic-title2="TOTAL SALES"
          statistic-title3="AOV"
          :chart-data="orders.series"
          :chart-options="chartOptions"
        />

        <div
          v-if="orders && orders.series && orders.series[0].data.length >= 1"
          class="d-flex flex-wrap justify-content-around"
        >
          <vue-apex-charts
            type="donut"
            height="500px"
            width="100%"
            class="my-2"
            :options="donutTotalChartOptions"
            :series="orders.providers.totalSeries"
          />
          <vue-apex-charts
            type="donut"
            height="500px"
            width="100%"
            class="my-2"
            :options="donutCountChartOptions"
            :series="orders.providers.countSeries"
          />
        </div>

        <div v-else class="p-5 text-center font-medium-3">
          No orders found for selected period!
        </div>
      </b-card-body>
    </b-card>
    <b-table hover responsive :borderless="true" :items="orders.items" :fields="fields">
      <template #cell(name)="data">
        <h5>{{ data.value }}</h5>
      </template>
      <template #cell(count)="data">
        <h5>{{ data.value }}</h5>
      </template>
      <template #cell(total)="data">
        <h5>{{ `$${data.value}` }}</h5>
      </template>
    </b-table>
  </div>
</template>
<style scoped></style>
<style>
@media all and (min-width: 550px) {
  .card-zero-padding .card-body {
    padding: 20px;
  }
}
.card-zero-padding .card-body {
  padding: 3px;
}
.dropdown-menu {
  max-height: 420px;
  overflow-y: auto;
}
</style>
<script>
import {
  BCard,
  BCardBody,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BTable,
  VBModal,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from '@/layouts/components/StatisticCardWithAreaChart.vue'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BTable,
    VueApexCharts,
    StatisticCardWithAreaChart,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      donutCountChartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#ffe700', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },
              total: {
                show: true,
                showAlways: true,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter(w) {
                  return w.globals.seriesTotal.reduce((a, b) => a + b, 0)
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: 'Integrations Orders count shares',
        },
      },
      donutTotalChartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#ffe700', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}%`
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: 'Integrations Orders value shares',
        },
      },
      orders: {},
      chartOptions: {
        grid: {
          show: true,
          padding: {
            left: 20,
            right: 20,
            top: 40,
            bottom: 60,
          },
        },
        colors: ['#ffe700', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          id: 'orders-analytics',
          toolbar: {
            show: true,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'string',
          lines: {
            show: true,
          },
          style: {
            colors: $themeColors.primary,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            show: true,
            style: {
              colors: $themeColors.primary,
            },
          },
        },
        yaxis: [
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
          },
          x: {
            show: true,
          },
          y: [
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `${y.toFixed(0)} orders`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
          ],
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: -10,
        },
        theme: {
          monochrome: {
            enabled: false,
            color: $themeColors.primary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      loadingAnalytics: false,
      day: 5,
      fields: [
        {
          key: 'name',
          label: 'Product Name',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Orders count',
          sortable: true,
        },
        { key: 'total', label: 'Total Sales', sortable: true },
      ],
    }
  },
  computed: {
    // eslint-disable-next-line object-shorthand,func-names
    dayString: function () {
      if (this.day === 0) {
        return 'today'
      }
      return this.day === 1 ? 'yesterday' : `last ${this.day} days`
    },
  },
  async created() {
    /*    const This = this
    setInterval(() => {
      console.log(This.chartOptions.xaxis.categories)
    }, 2000) */

    await this.ordersAnalytics(this.day)
  },
  methods: {
    kFormatter,
    async ordersAnalytics(days) {
      try {
        this.loadingAnalytics = true
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`admin/analytics/orders/${days}`)
        response = response.data
        if (response.type === 'success') {
          // const chartOptions = { labels: response.orders.labels }
          // this.chartOptions = Object.assign(this.chartOptions, chartOptions)
          this.chartOptions.labels = response.orders.labels
          this.orders.count = response.orders.count
          this.orders.total = response.orders.total
          this.orders.series = response.orders.series
          this.orders.items = response.orders.items.sort(this.compare)
          this.orders.providers = {}
          this.donutCountChartOptions.labels = response.orders.providers.labels
          this.donutTotalChartOptions.labels = response.orders.providers.labels
          this.orders.providers.totalSeries = response.orders.providers.totalSeries
          this.orders.providers.countSeries = response.orders.providers.countSeries
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.loadingAnalytics = false
      }
    },
    compare(a, b) {
      console.log(a, b)
      if (a.total > b.total) {
        return -1
      }
      if (a.total < b.total) {
        return 1
      }
      return 0
    },
  },
}
</script>
