<template>
  <div>
    <analytics-p-o-s />
  </div>
</template>
<style scoped></style>
<script>
import AnalyticsPOS from './AnalyticsPOS.vue'

export default {
  components: {
    AnalyticsPOS,
  },
  data() {
    return {}
  },
  beforeDestroy() {},
  mounted() {},
  methods: {},
}
</script>
