<template>
  <b-row>
    <div class="d-flex justify-content-around flex-wrap col-12">
      <b-col md="2" sm="4" class="my-1" hidden>
        <b-form-group class="">
          <label class="d-inline-block mr-1">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col class="my-1">
        <b-form-group
          label="Search Item"
          label-cols-sm="1"
          label-align-sm="top"
          label-for="filterInput"
          class=""
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </div>
    <b-col v-if="!loadingMenus" cols="12" class="dense">
      <b-table
        responsive
        show-empty
        :borderless="true"
        :per-page="perPage"
        :current-page="currentPage"
        :items="selectedMenu"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        @row-clicked="onRowClicked"
      >
        <template #cell(name)="data">
          <div class="d-flex align-items-center text-nowrap overflow-hidden full-name">
            <div :style="`opacity:${data.item.modifyList.length > 0 ? 1 : 0};`">
              <feather-icon
                :icon="`Chevron${data.item._showDetails ? 'Up' : 'Down'}Icon`"
                size="19"
                @click="data.toggleDetails"
              ></feather-icon>
            </div>
            <div
              class="font-weight-bold text-dark full_name text-capitalize ml-1"
              style="text-overflow: ellipsis; overflow: hidden"
            >
              {{ data.value }}
            </div>
          </div>
        </template>
        <template #cell(availability)="data">
          <div class="d-flex justify-content-end">
            <b-form-checkbox
              :checked="data.value"
              class="custom-control-success"
              switch
              @change.native.prevent="updateItemsAvailability($event, data.item.id)"
            />
          </div>
        </template>

        <template #empty="">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <h3 v-if="!loadingMenus" class="m-4">No Menus are available...</h3>
          </div>
        </template>
        <template #emptyfiltered="scope">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <h3 v-if="!loadingMenus" class="m-4">
              {{ scope.emptyFilteredText }}
            </h3>
          </div>
        </template>

        <template slot="row-details" slot-scope="data">
          <b-table
            v-for="modifyList of data.item.modifyList"
            :key="modifyList.id"
            :small="true"
            responsive
            show-empty
            centered
            :borderless="true"
            :per-page="perPage"
            :current-page="currentPage"
            :items="modifyList.modifiers"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            thead-class="hidden_table_header"
            @filtered="onFiltered"
            @row-clicked="onRowClicked"
          >
            <template #cell(name)="row">
              <div class="d-flex align-items-center text-nowrap overflow-hidden full-name">
                <div :style="`opacity:0;`">
                  <feather-icon
                    :icon="`Chevron${row.item._showDetails ? 'Up' : 'Down'}Icon`"
                    size="19"
                  ></feather-icon>
                </div>
                <div class="" style="margin-left: 6px">
                  <div class="font-small-1">{{ modifyList.name }}</div>
                  <div
                    class="font-weight-bold text-dark full_name text-capitalize"
                    style="text-overflow: ellipsis; overflow: hidden"
                  >
                    {{ row.value }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(availability)="row">
              <div class="d-flex justify-content-end" style="padding-right: 7px">
                <b-form-checkbox
                  :checked="row.value"
                  class="custom-control-danger"
                  switch
                  @change.native.prevent="updateItemsAvailability($event, row.item.modifyID)"
                />
              </div>
            </template>

            <template #empty="">
              <div class="d-flex justify-content-center flex-column align-items-center">
                <h3 v-if="!loadingMenus" class="m-4">No modifiers are available...</h3>
              </div>
            </template>
            <template #emptyfiltered="scope">
              <div class="d-flex justify-content-center flex-column align-items-center">
                <h3 v-if="!loadingMenus" class="m-4">
                  {{ scope.emptyFilteredText }}
                </h3>
              </div>
            </template>
          </b-table>
        </template>
      </b-table>
    </b-col>
    <div
      v-if="loadingMenus"
      class="d-flex justify-content-center flex-column align-items-center w-100 m-3"
    >
      <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
      <div class="m-2">Loading Menus, Please wait...</div>
    </div>
    <b-col class="d-none" cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        size="sm"
        class="my-0 text-center d-flex justify-content-center"
      />
    </b-col>
  </b-row>
</template>
<style scoped>
@media all and (min-width: 768px) {
  .full-name {
    max-width: 50vw;
  }
  .full-name {
    width: 40vw;
  }
}
@media all and (max-width: 768px) {
  .full-name {
    max-width: 50vw;
  }
  .full-names {
    width: 40vw;
  }
}
</style>
<script>
/* eslint-disable no-console,no-param-reassign,no-underscore-dangle */
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BFormCheckbox,
  BInputGroupAppend,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

// Fix up prefixing
window.AudioContext = window.AudioContext || window.webkitAudioContext

export default {
  components: {
    BSpinner,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      bus: new Vue(),
      perPage: 5000,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Item',
          sortable: true,
        },
        {
          key: 'availability',
          label: 'Available',
          sortable: true,
        },
        // { key: 'total', label: 'Total', sortable: true },
        // { key: 'payment_method', label: 'Payment', sortable: true },
      ],
      status: [
        {
          placed: 'Placed',
          confirmed: 'Confirmed',
          completed: 'Ready',
          cancelled: 'Cancelled',
          closed: 'Closed',
        },
        {
          placed: 'light-primary',
          confirmed: 'light-primary',
          completed: 'light-success',
          cancelled: 'light-danger',
          closed: 'dark',
        },
      ],
      paymentStatus: [
        {
          1: 'Paid by CC',
          2: 'Cash- Collect Payment',
        },
        {
          1: 'light-success',
          2: 'light-danger',
        },
      ],
      loadingMenus: true,
      orderDetailsDialog: false,
      newOrderDialog: false,
      menus: [],
      outlet: null,
      selectedMenu: [],
      spinner: {
        complete: false,
        cancel: false,
        reprintKitchen: false,
        reprintBar: false,
        reprintCustomer: false,
        close: false,
        adjust: false,
      },
      loadingButtons: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = 0
  },
  async created() {
    await this.getMenu()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onRowClicked(item, index, event) {
      // eslint-disable-next-line no-underscore-dangle,no-param-reassign
    },
    async getMenu() {
      this.loadingMenus = true
      try {
        /**
         * @type {Object}
         */
        const { data: response } = await this.$http.get(
          `integrations/omnimenu/get-list-outlet-inventory`
        )
        const { data } = response
        if (response.type === 'success' && data) {
          // eslint-disable-next-line prefer-destructuring
          this.outlet = data[0]
          // eslint-disable-next-line no-restricted-syntax
          for (const menu of data) {
            console.log(menu)
            this.$http
              .get(`integrations/omnimenu/get-items-inventory/${menu.id}`)
              .then(response2 => response2.data)
              .then(x => {
                console.log(x)
                if (x.type === 'success' && x) {
                  this.menus.push(x.data)
                  // eslint-disable-next-line prefer-destructuring
                  this.selectedMenu = this.menus[0]
                  this.loadingMenus = false
                } else if (x.type === 'error') {
                  console.error(response.display)
                  this.$bvToast.toast(response.display, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true,
                  })
                }
              })
          }
        } else if (response.type === 'error') {
          this.loadingMenus = false
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        this.loadingMenus = false
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      }
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async updateItemsAvailability(updateEvent, itemID) {
      console.log(updateEvent)
      const updateURL = updateEvent.target.checked ? 'available' : 'sold-out'
      try {
        /**
         * @type {Object}
         */
        const axiosResponse = await this.$http.get(
          `integrations/omnimenu/mark-as-${updateURL}/${this.outlet.id}/${itemID}`
        )
        const { data } = axiosResponse
        if (data.type === 'success') {
          this.showToast('Successfully updated', 'CheckIcon', 'success')
        } else if (data.type === 'error') {
          this.showToast('Error!', 'AlertTriangleIcon', 'danger', data.display)
          updateEvent.target.checked = !updateEvent.target.checked
        }
      } catch (e) {
        updateEvent.target.checked = !updateEvent.target.checked
        this.showToast('Error!', 'AlertTriangleIcon', 'danger', e.message)
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
