<template>
  <div>
    <div
      class="m-1 mb-3 d-flex justify-content-end"
      style="position: -webkit-sticky; position: sticky; top: 100px; z-index: 9"
    >
      <div class="d-flex align-items-center" style="">
        <b-form-group class="d-inline">
          <div class="">Range</div>
          <flat-pickr
            ref="datepickr"
            v-model="dateRange"
            class="form-control"
            :config="{ mode: 'range' }"
            @on-close="ordersAnalytics(null, $event, true)"
          />
        </b-form-group>
        <b-dropdown
          id="days-selector"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="loadingAnalytics"
          :text="dayString"
          variant="primary"
          class="ml-2"
        >
          <b-dropdown-item
            v-for="x of [0, 1, 7, 30, 'custom']"
            :key="x"
            :value="x"
            @click="
              ordersAnalytics(x)
              day = x
            "
          >
            {{
              x === 0
                ? 'today'
                : x === 1
                ? 'yesterday'
                : x === 'custom'
                ? 'Custom Range'
                : 'last ' + x + ' days'
            }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-tabs align="center">
      <b-tab title="Graph">
        <b-card class="card-zero-padding">
          <div
            v-if="loadingAnalytics"
            class="d-flex justify-content-center flex-column align-items-center m-3"
          >
            <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            <div class="m-2">Loading Analytics, Please wait...</div>
          </div>
          <b-card-body v-else class="p-0">
            <statistic-card-with-area-chart
              v-if="orders && orders.series && orders.series[0].data.length > 1"
              icon="UsersIcon"
              :statistic="kFormatter(orders.count)"
              :statistic2="'$' + kFormatter(orders.total)"
              :statistic3="'$' + (orders.total / orders.count).toFixed(2)"
              statistic-title="COMPLETED ORDERS"
              statistic-title2="TOTAL SALES"
              statistic-title3="AOV"
              :chart-data="orders.series"
              :chart-options="chartOptions"
            />

            <div
              v-if="orders && orders.series && orders.series[0].data.length >= 1"
              class="d-flex flex-wrap justify-content-around"
            >
              <vue-apex-charts
                type="donut"
                height="500px"
                width="100%"
                class="my-2"
                :options="donutTotalChartOptions"
                :series="orders.providers.totalSeries"
              />
              <vue-apex-charts
                type="donut"
                height="500px"
                width="100%"
                class="my-2"
                :options="donutCountChartOptions"
                :series="orders.providers.countSeries"
              />
            </div>

            <div v-else class="p-5 text-center font-medium-3">
              No orders found for selected period!
            </div>
          </b-card-body>
        </b-card>
      </b-tab>
      <b-tab title="Table">
        <b-table hover responsive :borderless="true" :items="orders.items" :fields="fields">
          <template #cell(name)="data">
            <h5>{{ data.value }}</h5>
          </template>
          <template #cell(count)="data">
            <h5>{{ data.value }}</h5>
          </template>
          <template #cell(total)="data">
            <h5>{{ `$${data.value}` }}</h5>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>
<style scoped></style>
<style>
@media all and (min-width: 550px) {
  .card-zero-padding .card-body {
    padding: 20px;
  }
}
.card-zero-padding .card-body {
  padding: 3px;
}
.dropdown-menu {
  max-height: 420px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<script>
/* eslint-disable no-console */
import {
  BCard,
  BTabs,
  BTab,
  BCardBody,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BTable,
  BFormGroup,
  VBModal,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from '@/layouts/components/StatisticCardWithAreaChart.vue'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BCardBody,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BTable,
    BFormGroup,
    flatPickr,
    VueApexCharts,
    StatisticCardWithAreaChart,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      donutCountChartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#6fb327', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)} Orders`
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: 'Integrations Orders count shares',
        },
      },
      donutTotalChartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#6fb327', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `$${parseInt(val)}`
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: 'gradient',
        },
        title: {
          text: 'Integrations Orders value shares',
        },
      },
      orders: {},
      chartOptions: {
        grid: {
          show: true,
          padding: {
            left: 20,
            right: 20,
            top: 40,
            bottom: 60,
          },
        },
        colors: ['#ffe700', '#00d4bd', '#826bf8', '#2b9bf4', '#FFA1A1'],
        chart: {
          id: 'orders-analytics',
          toolbar: {
            show: true,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'straight',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'string',
          lines: {
            show: true,
          },
          style: {
            colors: $themeColors.primary,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            show: true,
            style: {
              colors: $themeColors.primary,
            },
          },
        },
        yaxis: [
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
          {
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
          },
          x: {
            show: true,
          },
          y: [
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `${y.toFixed(0)} orders`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
            {
              formatter(y) {
                if (typeof y !== 'undefined') {
                  return `$${y.toFixed(0)}`
                }
                return y
              },
            },
          ],
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: -10,
        },
        theme: {
          monochrome: {
            enabled: false,
            color: $themeColors.primary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      loadingAnalytics: false,
      dateRange: '',
      showDatePicker: false,
      day: 0,
      fields: [
        {
          key: 'name',
          label: 'Product Name',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Number of orders',
          sortable: true,
        },
        { key: 'total', label: 'Total Sales', sortable: true },
      ],
    }
  },
  computed: {
    // eslint-disable-next-line object-shorthand,func-names
    dayString: function () {
      if (this.day === 0) {
        return 'today'
      }
      if (this.day === 1) {
        return 'yesterday'
      }
      if (this.day === 'custom') {
        return 'Custom Range'
      }
      return `last ${this.day} days`
    },
    cssProps() {
      return {
        '--primary-color': this.$vuetify.theme.primary,
      }
    },
  },
  async created() {
    /*    const This = this
    setInterval(() => {
      console.log(This.chartOptions.xaxis.categories)
    }, 2000) */

    await this.ordersAnalytics(this.day)
  },
  methods: {
    kFormatter,
    async ordersAnalytics(days, dateRange, submit) {
      console.log(days, dateRange, submit)
      if (!submit && days === 'custom') {
        setTimeout(() => this.$refs.datepickr.fp.open(), 200)

        // this.showDatePicker = true
        return
      }
      if (!submit) this.showDatePicker = false
      if (dateRange && dateRange.length === 2)
        // eslint-disable-next-line no-param-reassign
        dateRange = `${dateRange[0].getTime()}-${dateRange[1].getTime()}`
      try {
        this.loadingAnalytics = true
        /**
         * @type {Object}
         */
        let response = await this.$http.get(
          `analytics/delivery/days/${days}/${dateRange || ''}`
        )
        response = response.data
        if (response.type === 'success') {
          // const chartOptions = { labels: response.orders.labels }
          // this.chartOptions = Object.assign(this.chartOptions, chartOptions)
          this.chartOptions.labels = response.orders.labels
          this.orders.count = response.orders.count
          this.orders.total = response.orders.total
          this.orders.series = response.orders.series.slice(0, 2)
          this.orders.items = response.orders.items.sort(this.compare)
          this.orders.providers = {}
          this.donutCountChartOptions.labels = response.orders.providers.labels
          this.donutTotalChartOptions.labels = response.orders.providers.labels
          this.orders.providers.totalSeries = response.orders.providers.totalSeries
          this.orders.providers.countSeries = response.orders.providers.countSeries
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.loadingAnalytics = false
      }
    },
    compare(a, b) {
      if (a.total > b.total) {
        return -1
      }
      if (a.total < b.total) {
        return 1
      }
      return 0
    },
  },
}
</script>
