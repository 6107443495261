<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'users' }"> User List </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <user-view-user-plan-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6">
          <user-view-user-timeline-card class="d-none" :user-data="userData" />
        </b-col>
        <b-col cols="12" lg="6">
          <user-view-user-permissions-card :user-data="userData" />
        </b-col>
      </b-row>
    </template>
    <div v-else class="d-flex justify-content-center flex-column align-items-center w-100 m-3">
      <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
      <div class="m-2">Loading , Please wait...</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { BRow, BCol, BAlert, BLink, BSpinner } from 'bootstrap-vue'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import { useRouter } from '@core/utils/utils'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BSpinner,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    // InvoiceList,
  },
  setup() {
    const userData = ref(null)
    const router = useRouter()

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('app-user/fetchUser', { id: /* testing */ router.route.value.query.id })
      .then(response => {
        userData.value = response.data.user
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style></style>
